<template>
  <div class="packages bg-center bg-cover">
    <v-snackbar v-model="this.addSubError" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24" class="text-center">
       <p class="message">Nemaš osnovni paket/već si pretplaćen ili naplata nije uspjela.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.addSubError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.addSubMessageSuccess" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="success" elevation="24" class="text-center">
       <p class="message">{{ this.addSubMessageSuccessText }}</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.addSubMessageSuccess = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.addHardwareMessageSuccess" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="success" elevation="24" class="text-center">
       <p class="message">Uspješno si naručio uređaj.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.addHardwareMessageSuccess = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.addHardwareError" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24" class="text-center">
       <p v-if="this.addHardwareErrorMessage == null" class="message">Narudžba nije uspješna, pokušaj ponovo.</p>
       <p v-else class="message"> {{this.addHardwareErrorMessage }}</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.addHardwareError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-container> 
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12">
          <div class="relative top-1 left-{{ this.$vuetify.display.width >= 960 ? 20 : 12  }}">
            <p class="text-3xl font-semibold text-white">Odaberi</p>
            <h1 class="text-5xl font-extrabold text-white"><span class="text-red-700">MOVE</span>PAKET</h1>
            <p class="text-3xl font-semibold text-white">po svojoj mjeri</p>
            <p v-if="biggerScreen" class="mt-4 font-semibold text-lg text-white">Izaberi jedan od paketa sa bogatom ponudom TV kanala i <br> videoteka i uživaj u najkvalitetnijim TV sadržajima.</p>
            <p v-else class="mt-4 font-semibold text-base text-white">Izaberi jedan od paketa sa bogatom ponudom TV kanala i <br> videoteka i uživaj u najkvalitetnijim TV sadržajima.</p>
          </div>
        </v-col>
        <v-col cols="6">
        </v-col>
      </v-row>
      <v-row v-if="this.currentUser == null" class="subscribe-btn">
          <router-link v-if="biggerScreen" class="no-underline" :to="{name: 'SignInView'}">
            <v-btn rounded="pill" elevated="4" size="large" color="white" class="animate-bounce font-bold">
              <v-icon size="large" class="pr-3">mdi-account-plus</v-icon>
              <span class="font-bold">Postani korisnik</span>
            </v-btn>
          </router-link>
        </v-row>        
    </v-container>
  </div>
  <div class="packages-cards">
    <!-- Prikazivanje tri taba POSTPAID, PREPAID i UREĐAJI kada korisnik nije ulogovan -->
    <div v-if="this.currentUser == null || (isPromo && this.currentUserPromoType == null)">
      <v-container>
        <v-row v-if="!biggerScreen" class="subscribe-btn">
          <router-link class="no-underline" :to="{name: 'SignInView'}">
            <v-btn rounded="pill" color="white">
              <v-icon size="large" class="pr-3">mdi-account-plus</v-icon>
              <span class="font-bold">Postani korisnik</span>
            </v-btn>
          </router-link>
        </v-row>                                                          
      </v-container>
      <v-tabs v-if="biggerScreen" v-model="this.tab" style="color:#f58220; margin-top: 35px; margin-bottom: 30px;" align-tabs="center">
        <v-tab class="fs-5 fw-bold" value="1"><v-icon class="pr-2">mdi-calendar-sync</v-icon>POSTPAID PAKETI</v-tab>
        <v-tab class="fs-5 fw-bold" value="2"><v-icon class="pr-3">mdi-credit-card-plus</v-icon>PREPAID PAKETI</v-tab>
        <v-tab class="fs-5 fw-bold" value="3"><v-icon size="large" class="pr-2 pb-1">mdi-router-wireless</v-icon>UREĐAJI</v-tab>
      </v-tabs>
      <v-tabs v-else v-model="this.tab" style="color:#f58220; margin-top: 25px" align-tabs="center" fixed-tabs>
        <v-tab class="fs-7 fw-bold" value="1"><v-icon class="pr-1">mdi-calendar-sync</v-icon>POSTPAID</v-tab>
        <v-tab class="fs-7 fw-bold" value="2"><v-icon class="pr-1">mdi-credit-card-plus</v-icon>PREPAID</v-tab>
        <v-tab class="fs-7 fw-bold" value="3"><v-icon size="large" class="pr-1 pb-1">mdi-router-wireless</v-icon>UREĐAJI</v-tab>
      </v-tabs>
      <v-window v-model="this.tab">
        <v-window-item value="1">
          <v-container>
            <v-row align="center" justify="center">
              <v-col xs="12" sm="6" md="6" lg="6" xl="4">
                <v-card
                  style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
                  elevation="5"
                  class="pa-2 mt-5 sub-card"
                >
                  <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">MOVE TV start</v-card-title>
                  <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
                  <div class="pt-6 pb-6 pl-4">
                    <span class="feature">> 130+ TV kanala</span> <br>
                    <span class="feature">> 15+ radio kanala</span> <br>
                    <span class="feature">> Move Smart TV aplikacija</span> <br>
                    <span class="feature">> Move Mobilna aplikacija</span> <br>
                    <span class="feature">> Nick+ videoteka</span> <br>
                    <span class="feature">> AXN NOW videoteka</span> <br>
                    <span class="feature">> Epic Drama videoteka</span> <br>
                    <span class="feature">> History videoteka</span> <br>
                    <span class="feature">> Telcast videoteka</span> <br>
                    <span class="feature">> Dostupno na dva velika TV ekrana i na jednom mobilnom uređaju </span> <br>
                    <span class="feature">> Napredne TV funkcionalnosti na 70+ TV kanala (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span> <br>
                    <span class="feature">> Dostupnost dijela kanala u inostranstvu</span>
                  </div>
                  <v-divider class="mt-8" color="white"></v-divider>
                  <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
                      20,00 KM / mjesec
                  </v-card-text>
                  <!-- <v-col class="text-center">
                    <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Pretplati se</span>
                    </v-btn>
                  </v-col> -->
                </v-card>
              </v-col>
              <v-col xs="12" sm="6" md="6" lg="6" xl="4">
                <v-card
                  style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
                  elevation="5"
                  class="pa-2 mt-5 sub-card"
                >
                  <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">MOVE TV</v-card-title>
                  <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
                  <div class="pt-6 pb-6 pl-4">
                    <span class="feature">> 170+ TV kanala</span> <br>
                    <span class="feature">> 15+ radio kanala</span> <br>
                    <span class="feature">> Move Smart TV aplikacija</span> <br>
                    <span class="feature">> Move Mobilna aplikacija</span> <br>
                    <span class="feature">> Nick+ videoteka</span> <br>
                    <span class="feature">> AXN NOW videoteka</span> <br>
                    <span class="feature">> Epic Drama videoteka</span> <br>
                    <span class="feature">> History videoteka</span> <br>
                    <span class="feature">> Telcast videoteka</span> <br>
                    <span class="feature">> Dostupno na dva velika TV ekrana i na jednom mobilnom uređaju </span> <br>
                    <span class="feature">> Napredne TV funkcionalnosti na 70+ TV kanala (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span> <br>
                    <span class="feature">> Dostupnost dijela kanala u inostranstvu </span>
                  </div>
                  <v-divider class="mt-8" color="white"></v-divider>
                  <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
                      28,00 KM / mjesec
                  </v-card-text>
                  <!-- <v-col class="text-center">
                    <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Pretplati se</span>
                    </v-btn>
                  </v-col> -->
                </v-card>
              </v-col>
              <v-col xs="12" sm="6" md="6" lg="6" xl="4">
                <v-card
                  style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
                  elevation="5"
                  class="pa-2 mt-5 sub-card"
                >
                  <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">MOVE TV plus</v-card-title>
                  <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
                  <div class="pt-6 pb-6 pl-4">
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature">> Dodatni paket TV kanala</span> <br>
                    <span class="feature">> Dostupan uz aktivan MOVE TV start ili MOVE TV paket</span> <br>
                    <span class="feature">> Preko 50 TV kanala</span> <br>
                    <span class="feature">> Napredne TV funkcionalnosti (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span><br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                  </div>
                  <v-divider class="mt-8" color="white"></v-divider>
                  <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
                    6,44 KM / mjesec
                  </v-card-text>
                  <!-- <v-col class="text-center">
                    <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Pretplati se</span>
                    </v-btn>
                  </v-col> -->
                </v-card>
              </v-col>
              <v-col xs="12" sm="6" md="6" lg="6" xl="4">
                <v-card
                  style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
                  elevation="5"
                  class="pa-2 mt-5 sub-card"
                >
                  <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">ELITA</v-card-title>
                  <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
                  <div class="pt-6 pb-6 pl-4">
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature">> Dodatni paket TV kanala</span> <br>
                    <span class="feature">> Dostupan uz aktivan MOVE TV start ili MOVE TV paket</span> <br>
                    <span class="feature">> Elita Live 1, Elita Live 2, Elita Live 3, Elita Live 4</span> <br>
                    <span class="feature">> Napredne TV funkcionalnosti (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span><br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                  </div>
                  <v-divider class="mt-8" color="white"></v-divider>
                  <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
                      9,90 KM / mjesec
                  </v-card-text>
                  <!-- <v-col class="text-center">
                    <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Pretplati se</span>
                    </v-btn>
                  </v-col> -->
                </v-card>
              </v-col>
            </v-row>
          </v-container>
      </v-window-item>
      <v-window-item value="2">
        <v-container>
            <v-row align="center" justify="center">
              <v-col xs="12" sm="6" md="6" lg="6" xl="4">
                <v-card
                  style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
                  elevation="5"
                  class="pa-2 mt-5 sub-card"
                >
                  <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">MOVE TV</v-card-title>
                  <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
                  <div class="pt-6 pb-6 pl-4">
                    <span class="feature">> 170+ TV kanala</span> <br>
                    <span class="feature">> 15+ radio kanala</span> <br>
                    <span class="feature">> Move Smart TV aplikacija</span> <br>
                    <span class="feature">> Move Mobilna aplikacija</span> <br>
                    <span class="feature">> Nick+ videoteka</span> <br>
                    <span class="feature">> AXN NOW videoteka</span> <br>
                    <span class="feature">> Epic Drama videoteka</span> <br>
                    <span class="feature">> History videoteka</span> <br>
                    <span class="feature">> Telcast videoteka</span> <br>
                    <span class="feature">> Dostupno na dva velika TV ekrana i na jednom mobilnom uređaju </span> <br>
                    <span class="feature">> Napredne TV funkcionalnosti na 70+ TV kanala (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span> <br>
                    <span class="feature">> Dostupnost dijela kanala u inostranstvu </span>
                  </div>
                  <v-divider class="mt-8" color="white"></v-divider>
                  <v-card-text class="fs-3 text-white fw-bold text-center" style="padding-top:3px; padding-bottom: 8px;">
                    <v-btn class="font-bold" variant="outlined" elevation="1" style="letter-spacing:0.5px; margin-top: 8px;" size="large">
                      <span class="font-bold btn-text">Prikaži opcije</span>
                      <v-icon size="x-large" class="pl-4">mdi-chevron-down-circle</v-icon>
                      <v-menu activator="parent">
                        <v-list style="background:white; margin:0; padding: 0;">
                          <v-list-item
                            class="text-center"
                            v-for="(item, index) in this.periods"
                            :key="index"
                            :value="index"
                            style="border-bottom: 0.1px solid black;"
                            :disabled="true"
                            >
                            <v-list-item-title class="font-bold text-black">{{ item.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-btn>
                  </v-card-text>
                  <!-- <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
                    {{ this.selectedPeriod }}
                  </v-card-text> -->
                  <!-- <v-col class="text-center">
                    <v-btn elevation="4" size="x-large" class="bg-white text-2xl font-bold pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Prikaži</span>
                      <v-icon size="large" class="pl-4">mdi-chevron-down-circle</v-icon>
                      <v-menu activator="parent">
                        <v-list style="background:white; margin:0; padding: 0;">
                          <v-list-item
                            class="text-center"
                            v-for="(item, index) in this.periods"
                            :key="index"
                            :value="index"
                            style="border-bottom: 0.1px dotted black;"
                            @click="openSignIn()"
                            >
                            <v-list-item-title class="font-bold text-black">{{ item.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-btn>
                  </v-col> -->
                </v-card>
              </v-col>
              <v-col xs="12" sm="6" md="6" lg="6" xl="4">
                <v-card
                  style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
                  elevation="5"
                  class="pa-2 mt-5 sub-card"
                >
                  <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">MOVE TV extra</v-card-title>
                  <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
                  <div class="pt-6 pb-6 pl-4">
                    <br v-if="this.$vuetify.display.width <= 1280 && this.$vuetify.display.width > 600">
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature">> Dodatni paket TV kanala</span> <br>
                    <span class="feature">> Dostupan uz aktivan MOVE TV paket</span> <br>
                    <span class="feature">> Preko 50 TV kanala</span> <br>
                    <span class="feature">> Napredne TV funkcionalnosti (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span><br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                  </div>
                  <v-divider class="mt-8" color="white"></v-divider>
                  <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
                    10,00 KM / mjesec
                  </v-card-text>
                  <!-- <v-col class="text-center">
                    <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Pretplati se</span>
                    </v-btn>
                  </v-col> -->
                </v-card>
              </v-col>
              <v-col xs="12" sm="6" md="6" lg="6" xl="4">
                <v-card
                  style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
                  elevation="5"
                  class="pa-2 mt-5 sub-card"
                >
                  <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">ELITA</v-card-title>
                  <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
                  <div class="pt-6 pb-6 pl-4">
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature">> Dodatni paket TV kanala</span> <br>
                    <span class="feature">> Dostupan uz aktivan MOVE TV start ili MOVE TV paket</span> <br>
                    <span class="feature">> Elita Live 1, Elita Live 2, Elita Live 3, Elita Live 4</span> <br>
                    <span class="feature">> Napredne TV funkcionalnosti (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span><br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                    <span class="feature"></span> <br>
                  </div>
                  <v-divider class="mt-8" color="white"></v-divider>
                  <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
                      9,90 KM / mjesec
                  </v-card-text>
                  <!-- <v-col class="text-center">
                    <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Pretplati se</span>
                    </v-btn>
                  </v-col> -->
                </v-card>
              </v-col>
            </v-row>
          </v-container>
      </v-window-item>
      <v-window-item value="3">
        <v-container>
          <v-row align="center" justify="center">
            <v-col xs="12" sm="6" md="6" lg="6" xl="4">
              <v-card
                  style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
                  elevation="5"
                  class="pa-2 mt-5 sub-card"
                >
                  <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">MOVE STB uređaj</v-card-title>
                  <div class="stb-image">
                    <v-img
                    style="margin-top: 10px; margin-bottom: 7px"
                    :height="this.$vuetify.display.width >= 960 ? '290px' : '220px'"
                    :width="this.$vuetify.display.width >= 960 ? '290px' : '220px'"
                    :src="require(`@/assets/images/stb-image.png`)"
                    alt="STB image"
                  ></v-img>
                  </div>
                  <p class="pl-2 mt-6 mb-6 text-white text-center" style="font-size: 11px">*Prikazana fotografija može se razlikovati od originala</p>
                  <v-divider color="white"></v-divider>
                  <v-card-text class="fs-2 mt-3 text-white fw-bold text-center" style="padding-top:3px; padding-bottom: 8px;">79,00 KM</v-card-text>
                  <v-card-text class="fs-3 text-white fw-bold text-center" style="padding-top:3px; padding-bottom: 8px;">
                    
                    <v-btn class="font-bold mb-1" variant="outlined" elevation="1" style="letter-spacing:0.5px; margin-top: 8px;" size="large">
                      <span class="font-bold btn-text">Način plaćanja</span>
                      <v-icon size="x-large" class="pl-4">mdi-chevron-down-circle</v-icon>
                      <v-menu activator="parent" transition="slide-y-transition">
                        <v-list style="background:white; margin:0; padding: 0;">
                          <v-list-item
                            class="text-center"
                            v-for="(item, index) in this.stbOptions"
                            :key="index"
                            :value="index"
                            style="border-bottom: 0.1px solid black;"
                            :disabled="true"
                            >
                            <v-list-item-title style="font-weight: 700;">{{ item.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-btn>
                  </v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
      </v-window>
    </div>
    <!--PAKETI UKOLIKO JE KORISNIK ULOGOVAN-->
    <v-container v-else>
      <!-- <h1 class="text-orange-600 text-center pb-8 font-semibold">MOVE PAKETI</h1> -->
      <div class="text-center">
        <v-chip class="text-center mb-10" color="#ed1a3b" label size="x-large">
          <v-icon size="large" start icon="mdi-layers"></v-icon>
          <span class="fw-bold fs-5 tracking-widest">PAKETI</span>
        </v-chip>   
      </div>
    <v-row align="center" justify="center">
    <v-col v-if="(!isPrepaid && !isPromo) || (isPromo && this.currentUserPromoType == 0)" xs="12" sm="6" md="6" lg="6" xl="4">
      <v-card
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
          elevation="5"
          class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
           MOVE TV start
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['9'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">20 KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
        </div>
        <div class="pt-6 pb-6 pl-4">
          <span class="feature">> 130+ TV kanala</span> <br>
          <span class="feature">> 15+ radio kanala</span> <br>
          <span class="feature">> Move Smart TV aplikacija</span> <br>
          <span class="feature">> Move Mobilna aplikacija</span> <br>
          <span class="feature">> Nick+ videoteka</span> <br>
          <span class="feature">> AXN NOW videoteka</span> <br>
          <span class="feature">> Epic Drama videoteka</span> <br>
          <span class="feature">> History videoteka</span> <br>
          <span class="feature">> Telcast videoteka</span> <br>
          <span class="feature">> Dostupno na dva velika TV ekrana i na jednom mobilnom uređaju </span> <br>
          <span class="feature">> Napredne TV funkcionalnosti na 70+ TV kanala (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span> <br>
          <span class="feature">> Dostupnost dijela kanala u inostranstvu </span>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
        <div v-if="!isPrepaid && !isPromo">
        <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['9'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
            20,00 KM / mjesec
        </v-card-text>
        <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
            Ističe: {{this.currentUserPackages['9'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['9'].getPackageExpDate : '/'}}
        </v-card-text>
        <v-row>
          <v-col class="text-center">
            <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['9'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(9)" @click="this.openDialog(9)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
              <span class="font-semibold btn-text">Pretplati se</span>
            </v-btn> 
            <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
              <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
              <span class="fw-semibold fs-3">AKTIVNA</span>
            </v-chip>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
            20,00 KM / mjesec
        </v-card-text>
        <v-row>
          <v-col class="text-center">
            <v-btn @click="this.openPostpaidApplicationPage()" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
              <span class="font-semibold btn-text">Pretplati se</span>
            </v-btn> 
          </v-col>
        </v-row>
      </div>
      </v-card>
    </v-col>
    <v-col xs="12" sm="6" md="6" lg="6" xl="4">
      <v-card
          v-if="(isPrepaid && !isPromo) || (isPromo && this.currentUserPromoType == 1)"
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
          elevation="5"
          class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          MOVE TV
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && !isMainActive()) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{ this.getActivePackagePrice() }}</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
        </div>
        <div class="pt-6 pb-6 pl-4">
          <span class="feature">> 170+ TV kanala</span> <br>
          <span class="feature">> 15+ radio kanala</span> <br>
          <span class="feature">> Move Smart TV aplikacija</span> <br>
          <span class="feature">> Move Mobilna aplikacija</span> <br>
          <span class="feature">> Nick+ videoteka</span> <br>
          <span class="feature">> Epic Drama videoteka</span> <br>
          <span class="feature">> History videoteka</span> <br>
          <span class="feature">> Telcast videoteka</span> <br>
          <span class="feature">> Dostupno na dva velika TV ekrana i na jednom mobilnom uređaju </span> <br>
          <span class="feature">> Napredne TV funkcionalnosti(gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span> <br>
          <span class="feature">> Dostupnost dijela kanala u inostranstvu </span>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
        <div v-if="this.currentUserPackages != null && this.currentUser != null && isMainActive() == true">
          <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
            Ističe: {{ this.getMainExpDate() }}
          </v-card-text>
        </div>
        <div v-else>
        <v-card-text v-if="this.selectedPeriod == null" class="fs-3 mt-3 text-white fw-bold text-center">
          Period važenja:
        </v-card-text>
        <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
          {{ this.selectedPeriod }}
        </v-card-text>
        </div>
        <v-row>
          <v-col class="text-center">
            <div v-if="this.currentUserPackages != null && this.currentUser != null && isMainActive() == true">
              <v-chip style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                <span class="fw-semibold fs-3">AKTIVNA</span>
              </v-chip>
            </div>
            <div v-else>
              <v-btn v-if="this.selectedPeriod != null" :disabled="this.currentUser == null || this.isMainDisabled()" @click="this.openDialog(this.selectedPeriodId)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span v-if="this.currentUserPackages != null && this.isMainActive()" class="font-semibold btn-text">Aktivna</span>
                <span v-else class="font-semibold btn-text">Pretplati se</span>
              </v-btn>
              <v-btn v-if="this.selectedPeriod != null" @click="removeSelection()" color="black" icon="mdi-close-thick" class="ml-2"></v-btn>
              <v-btn v-else elevation="4" size="x-large" class="bg-white text-2xl font-bold mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Izaberi</span>
                <v-icon size="large" class="pl-4">mdi-chevron-down-circle</v-icon>
                <v-menu activator="parent">
                  <v-list style="background:white; margin:0; padding: 0;">
                    <v-list-item
                      class="text-center"
                      v-for="(item, index) in this.periods"
                      :key="index"
                      :value="index"
                      @click="selectPeriod(item.value)"
                      style="border-bottom: 0.1px dotted black;"
                      >
                      <v-list-item-title class="font-bold text-black">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn>
           </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card
          v-else
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
          elevation="5"
          class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          MOVE TV
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['1'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">28 KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
        </div>
        <div class="pt-6 pb-6 pl-4">
          <span class="feature">> 170+ TV kanala</span> <br>
          <span class="feature">> 15+ radio kanala</span> <br>
          <span class="feature">> Move Smart TV aplikacija</span> <br>
          <span class="feature">> Move Mobilna aplikacija</span> <br>
          <span class="feature">> Nick+ videoteka</span> <br>
          <span class="feature">> Epic Drama videoteka</span> <br>
          <span class="feature">> History videoteka</span> <br>
          <span class="feature">> Telcast videoteka</span> <br>
          <span class="feature">> Dostupno na dva velika TV ekrana i na jednom mobilnom uređaju </span> <br>
          <span class="feature">> Napredne TV funkcionalnosti(gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span> <br>
          <span class="feature">> Dostupnost dijela kanala u inostranstvu </span> <br>
          <span class="feature"></span><br>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
        <div v-if="!isPrepaid && !isPromo">
          <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['1'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
              28,00 KM / mjesec
          </v-card-text>
          <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
              Ističe: {{this.currentUserPackages['1'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['1'].getPackageExpDate : '/'}}
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['1'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(1)" @click="this.openDialog(1)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
              <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                <span class="fw-semibold fs-3">AKTIVNA</span>
              </v-chip>
            </v-col>
          </v-row>
       </div>
       <div v-else>
          <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
              28,00 KM / mjesec
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn @click="this.openPostpaidApplicationPage()" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
            </v-col>
          </v-row>
       </div>
      </v-card>
    </v-col>
    <v-col xs="12" sm="6" md="6" lg="6" xl="4">
      <v-card
        v-if="(isPrepaid && !isPromo) || (isPromo && this.currentUserPromoType == 1)"
        style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        elevation="5"
        class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          MOVE TV extra                                                                  
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['10'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{this.currentUserPackages['10'].total_amount}} KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
        </div>
        <div class="pt-6 pb-6 pl-4">
          <br v-if="this.$vuetify.display.width <= 1280 && this.$vuetify.display.width > 600">
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature">> Dodatni paket TV kanala</span> <br>
          <span class="feature">> Dostupan uz aktivan MOVE TV paket</span> <br>
          <span class="feature">> Preko 50 TV kanala</span> <br>
          <span class="feature">> Napredne TV funkcionalnosti (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span><br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
        <div v-if="isPrepaid && !isPromo">
          <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['10'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
              10,00 KM / mjesec
          </v-card-text>
          <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
            Ističe: {{this.currentUserPackages != null && this.currentUserPackages['10'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['10'].getPackageExpDate : '/'}}
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['10'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(10)" @click="this.openDialog(10)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-3 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
              <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                <span class="fw-semibold fs-3">AKTIVNA</span>
              </v-chip>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
              10,00 KM / mjesec
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn @click="this.openDialog(10)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-1 mt-1 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-card
        v-else
        style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        elevation="5"
        class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          MOVE TV plus
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['4'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{this.currentUserPackages['4'].total_amount}} KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
        </div>
        <div class="pt-6 pb-6 pl-4">
          <br v-if="this.$vuetify.display.width <= 1280 && this.$vuetify.display.width > 600">
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature">> Dodatni paket TV kanala</span> <br>
          <span class="feature">> Dostupan uz aktivan MOVE TV start/MOVE TV</span> <br>
          <span class="feature">> Preko 50 TV kanala</span> <br>
          <span class="feature">> Napredne TV funkcionalnosti (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span><br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
        <div v-if="!isPrepaid && !isPromo">
          <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['4'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
              6,44 KM / mjesec
          </v-card-text>
          <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
            Ističe: {{this.currentUserPackages['4'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['4'].getPackageExpDate : '/'}}
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['4'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(4)" @click="this.openDialog(4)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
              <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                <span class="fw-semibold fs-3">AKTIVNA</span>
              </v-chip>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
            6,44 KM / mjesec
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn @click="this.openPostpaidApplicationPage()" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-dialog v-model="this.addSubDialog" width="auto">
        <v-card>
          <v-card-title class="mt-2"><span class="font-semibold">Želite se pretplatiti?</span></v-card-title>
          <v-card-text>
            Klikom na dugme PRETPLATI SE kupujete paket koji će Vam biti dostupan sve do isteka trajanja paketa. <br>
           <span v-if="this.currentUserPackages != null && this.currentUserPackages['1'].isPackageActive == 1">Usluga će se automatski obnavljati po isteku.</span> 
          </v-card-text>
          <v-card-actions class="ma-2">
            <v-spacer></v-spacer>
            <v-btn class="delete-btn" variant="tonal" @click="this.addSubDialog = false; this.loading = false"><span class="font-semibold">Otkaži</span></v-btn>                         
            <v-btn :loading="this.loading" class="delete-btn text-white" variant="flat" color="#b10d28" @click="this.subscribe(this.currentPackageId)"><span class="text-white font-semibold">Pretplati se</span></v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
      <v-dialog v-model="this.addCardWarningDialog" width="auto">
        <v-card>
          <v-card-title class="mt-2"><span class="font-semibold">Želite se pretplatiti?</span></v-card-title>
          <v-card-text>
            Da bi se pretplatio na ovaj paket, na svom profilu prvo moraš dodati bankovnu karticu ili dopuniti račun.
          </v-card-text>
          <v-card-actions class="ma-2">
            <v-spacer></v-spacer>
            <v-btn class="delete-btn" variant="tonal" @click="this.addCardWarningDialog = false; this.loading = false"><span class="font-semibold">Otkaži</span></v-btn>                         
            <!-- <v-btn :loading="this.loading" class="delete-btn text-white" variant="flat" color="#b10d28" @click="this.subscribe(this.currentPackageId)"><span class="text-white font-semibold">Dodaj karticu</span></v-btn> -->
            <router-link class="no-underline" :to="{name: 'ProfileView'}">
              <v-btn class="ml-2 delete-btn text-white" variant="flat" color="#b10d28"><span class="text-white font-semibold">Idi na profil</span></v-btn>
            </router-link>
          </v-card-actions>
          </v-card>
      </v-dialog>
    </v-col>
    <v-col xs="12" sm="6" md="6" lg="6" xl="4">
      <v-card
        v-if="(isPrepaid && !isPromo) || (isPromo && this.currentUserPromoType == 1)"
        style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        elevation="5"
        class="pa-2 mt-5 sub-card"
      >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
            ELITA                                                                 
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['12'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{this.currentUserPackages['12'].total_amount}} KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
        </div>
        <div class="pt-6 pb-6 pl-4">
          <br v-if="this.$vuetify.display.width <= 1280 && this.$vuetify.display.width > 600">
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature">> Dodatni paket TV kanala</span> <br>
          <span class="feature">> Dostupan uz aktivan MOVE TV start ili MOVE TV paket</span> <br>
          <span class="feature">> Elita Live 1, Elita Live 2, Elita Live 3, Elita Live 4</span> <br>
          <span class="feature">> Napredne TV funkcionalnosti (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span><br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
        <div v-if="isPrepaid && !isPromo">
          <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['12'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
              9,90 KM / mjesec
          </v-card-text>
          <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
            Ističe: {{this.currentUserPackages != null && this.currentUserPackages['12'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['12'].getPackageExpDate : '/'}}
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['12'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(12)" @click="this.openDialog(12)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-3 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
              <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                <span class="fw-semibold fs-3">AKTIVNA</span>
              </v-chip>
            </v-col> 
          </v-row>
        </div>
        <div v-else>
          <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
              9,90 KM / mjesec
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn @click="this.openDialog(12)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-1 mt-1 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-card
        v-else
        style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        elevation="5"
        class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          ELITA
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['12'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{this.currentUserPackages['12'].total_amount}} KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Paket po tvom!</v-card-subtitle>
        </div>
        <div class="pt-6 pb-6 pl-4">
          <br v-if="this.$vuetify.display.width <= 1280 && this.$vuetify.display.width > 600">
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature">> Dodatni paket TV kanala</span> <br>
          <span class="feature">> Dostupan uz aktivan MOVE TV start ili MOVE TV paket</span> <br>
          <span class="feature">> Elita Live 1, Elita Live 2, Elita Live 3, Elita Live 4</span> <br>
          <span class="feature">> Napredne TV funkcionalnosti (gledaj ispočetka, gledaj propušteno, pauziraj TV i video na zahtjev)</span><br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
          <span class="feature"></span> <br>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
        <div v-if="!isPrepaid && !isPromo">
          <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['12'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
              9,90 KM / mjesec
          </v-card-text>
          <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
            Ističe: {{this.currentUserPackages['12'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['12'].getPackageExpDate : '/'}}
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['12'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(12)" @click="this.openDialog(12)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
              <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                <span class="fw-semibold fs-3">AKTIVNA</span>
              </v-chip>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
            9,90 KM / mjesec
          </v-card-text>
          <v-row>
            <v-col class="text-center">
              <v-btn @click="this.openPostpaidApplicationPage()" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
  </v-container>
  <v-container v-if="this.tab != 3" class="mt-10">
    <div class="text-center">
        <v-chip v-if="biggerScreen" class="text-center mb-10" color="#f58220" label size="x-large">
          <v-icon size="large" start icon="mdi-video"></v-icon>
          <span class="fw-bold fs-5 tracking-widest">VIDEOTEKE</span>
        </v-chip>
        <v-chip v-else class="text-center mb-10" color="#f58220" label size="large">
          <v-icon size="large" start icon="mdi-video"></v-icon>
          <span class="fw-semibold fs-6 tracking-widest">VIDEOTEKE</span>
        </v-chip>
      </div>
    <v-row align="center" justify="center">
      <v-col xs="6" sm="6" md="6" lg="6" xl="3">
        <v-card
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
          elevation="5"
          class="pa-2 mt-5 sub-card"     
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          Apollon
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['7'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Videoteka po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{this.currentUserPackages['7'].total_amount}} KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Videoteka po tvom!</v-card-subtitle>
        </div> 
        <div class="pt-6 pb-6 pl-4">
          <span class="feature"> > Dostupna uz aktivan osnovni paket</span> <br>
          <span class="feature"> > Preko 6000 filmova</span> <br>
          <span class="feature"> > Preko 180 serija</span> <br>
          <span class="feature"> > Dostupna samo na teritoriji BiH</span> <br>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
        <div v-if="!isPromo">
          <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['7'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
            6,90 KM / mjesec
          </v-card-text>
          <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
            Ističe: {{this.currentUserPackages['7'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['7'].getPackageExpDate : '/'}}
          </v-card-text>
          <v-row v-if="this.currentUser != null">
            <v-col v-if="this.currentUser" class="text-center">
              <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['7'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(7)" @click="this.openDialog(7)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
              <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                <span class="fw-semibold fs-3">AKTIVNA</span>
              </v-chip> 
            </v-col>
            <v-col v-else class="text-center">
              <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
            6,90 KM / mjesec
          </v-card-text>
          <v-row v-if="this.currentUserPromoType != null">
            <v-col class="text-center">
              <v-btn @click="openDialog(7)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                <span class="font-semibold btn-text">Pretplati se</span>
              </v-btn> 
            </v-col>
          </v-row>
        </div>
        </v-card>
      </v-col>
      <v-col xs="6" sm="6" md="6" lg="6" xl="3">
        <v-card
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
          elevation="5"
          class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          Superstar
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['5'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Videoteka po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{this.currentUserPackages['5'].total_amount}} KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Videoteka po tvom!</v-card-subtitle>
        </div>   
        <div class="pt-6 pb-6 pl-4">
            <span class="feature"> > Dostupna uz aktivan osnovni paket</span> <br>
            <span class="feature"> > Preko 40 serija</span> <br>
            <span class="feature"> > Dostupna samo na teritoriji BiH</span> <br>
            <span class="feature"></span><br>
          </div>
          <v-divider class="mt-8" color="white"></v-divider>
          <div v-if="!isPromo">
            <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['5'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
              4,68 KM / mjesec
            </v-card-text>
            <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
              Ističe: {{this.currentUserPackages['5'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['5'].getPackageExpDate : '/'}}
            </v-card-text>
            <v-row v-if="this.currentUser != null">
              <v-col v-if="this.currentUser" class="text-center">
                <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['5'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(5)" @click="this.openDialog(5)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
                <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                  <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                  <span class="fw-semibold fs-3">AKTIVNA</span>
                </v-chip> 
              </v-col>
              <v-col v-else class="text-center">
                <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
              4,68 KM / mjesec
            </v-card-text>
            <v-row v-if="this.currentUserPromoType != null">
              <v-col class="text-center">
                <v-btn @click="openDialog(5)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col xs="6" sm="6" md="6" lg="6" xl="3">
        <v-card
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
          elevation="5"
          class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          Pickbox NOW
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['6'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Videoteka po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{this.currentUserPackages['6'].total_amount}} KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Videoteka po tvom!</v-card-subtitle>
        </div>  
        <div class="pt-6 pb-6 pl-4">
            <span class="feature"> > Dostupna uz aktivan osnovni paket</span> <br>
            <span class="feature"> > Preko 450 filmova</span> <br>
            <span class="feature"> > Preko 100 serija</span> <br>
            <span class="feature"> > Dostupna samo na teritoriji BiH</span> <br>
          </div>
          <v-divider class="mt-8" color="white"></v-divider>
          <div v-if="!isPromo">
            <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['6'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
              8,78 KM / mjesec
            </v-card-text>
            <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
              Ističe: {{this.currentUserPackages['6'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['6'].getPackageExpDate : '/'}}
            </v-card-text>
            <v-row v-if="this.currentUser != null">
              <v-col v-if="this.currentUser" class="text-center">
                <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['6'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(6)" @click="this.openDialog(6)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
                <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                  <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                  <span class="fw-semibold fs-3">AKTIVNA</span>
                </v-chip> 
              </v-col>
              <v-col v-else class="text-center">
                <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
              8,78 KM / mjesec
            </v-card-text>
            <v-row v-if="this.currentUserPromoType != null">
              <v-col class="text-center">
                <v-btn @click="openDialog(6)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col xs="6" sm="6" md="6" lg="6" xl="3">
        <v-card
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
          elevation="5"
          class="pa-2 mt-5 sub-card"
        >
        <v-card-title :style="biggerScreen ? 'font-size: 45px;' : 'font-size: 35px;'" class="text-center mt-4 fw-semibold text-white card-title">
          FilmBox
        </v-card-title>
        <div v-if="!isPromo">
          <v-card-subtitle v-if="(this.currentUserPackages != null && this.currentUserPackages['11'].isPackageActive == 0) || this.currentUser == null" class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Videoteka po tvom!</v-card-subtitle>
          <v-card-subtitle v-else class="fs-5 pb-2 text-center fw-semibold font-italic card-subtitle text-white">{{this.currentUserPackages['11'].total_amount}} KM / mjesec</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-subtitle class="fs-5 text-center fw-semibold font-italic card-subtitle text-white">Videoteka po tvom!</v-card-subtitle>
        </div>    
        <div class="pt-6 pb-6 pl-4">
          <span class="feature"> > Dostupna uz aktivan osnovni paket</span> <br>
          <span class="feature"> > Preko 450 filmova</span> <br>
          <span class="feature"> > Preko 100 serija</span> <br>
          <span class="feature"> > Dostupna samo na teritoriji BiH</span> <br>
        </div>
        <v-divider class="mt-8" color="white"></v-divider>
          <div v-if="!isPromo">
            <v-card-text v-if="(this.currentUserPackages != null && this.currentUserPackages['11'].isPackageActive == 0) || this.currentUser == null" class="fs-3 mt-3 text-white fw-bold text-center">
              4,68 KM / mjesec
            </v-card-text>
            <v-card-text v-else class="fs-3 mt-3 text-white fw-bold text-center">
              Ističe: {{this.currentUserPackages['11'].getPackageExpDate != 'NO_DATA' ? this.currentUserPackages['11'].getPackageExpDate : '/'}}
            </v-card-text>
            <v-row v-if="this.currentUser != null">
              <v-col v-if="this.currentUser" class="text-center">
                <v-btn v-if="(this.currentUserPackages != null && this.currentUserPackages['11'].isPackageActive == 0) || this.currentUser == null" :disabled="this.isDisabled(11)" @click="this.openDialog(11)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
                <v-chip v-else style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                  <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                  <span class="fw-semibold fs-3">AKTIVNA</span>
                </v-chip> 
              </v-col>
              <v-col v-else class="text-center">
                <v-btn @click="openSignIn()" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-card-text class="fs-3 mt-3 text-white fw-bold text-center">
              4,68 KM / mjesec
            </v-card-text>
            <v-row v-if="this.currentUserPromoType != null">
              <v-col class="text-center">
                <v-btn @click="openDialog(11)" elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-2 pr-4 pl-4 w-60">
                  <span class="font-semibold btn-text">Pretplati se</span>
                </v-btn> 
              </v-col>
            </v-row>
          </div>  
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-if="this.currentUser != null && (isPrepaid || isPostpaid) || isPromo && this.currentUserPromoType != null" class="mt-10">
    <div class="text-center">
      <v-chip v-if="biggerScreen" class="text-center mb-10" color="#5dcc84" label size="x-large">
        <v-icon size="large" start icon="mdi-router-wireless"></v-icon>
        <span class="fw-bold fs-5 tracking-widest">UREĐAJI</span>
      </v-chip>
      <v-chip v-else class="text-center mb-10" color="#5dcc84" label size="large">
        <v-icon size="large" start icon="mdi-router-wireless"></v-icon>
        <span class="fw-semibold fs-6 tracking-widest">UREĐAJI</span>
      </v-chip>
    </div>
    <v-row align="center" justify="center">
      <v-col xs="12" sm="6" md="6" lg="6" xl="4">
        <v-card
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
          elevation="5"
          class="pa-2 mt-5 sub-card"
        >
          <v-card-title class="fs-1 text-center mt-4 fw-semibold text-white card-title">MOVE STB uređaj</v-card-title>
          <div class="stb-image">
            <v-img
              style="margin-top: 10px; margin-bottom: 7px"
              :height="this.$vuetify.display.width >= 960 ? '250px' : '220px'"
              :width="this.$vuetify.display.width >= 960 ? '250px' : '220px'"
              :src="require(`@/assets/images/stb-image.png`)"
              alt="STB image"
            ></v-img>
          </div>
          <p class="pl-2 mt-6 mb-6 text-white text-center" style="font-size: 11px">*Prikazana fotografija može se razlikovati od originala</p>
          <v-divider color="white"></v-divider>
          <div>
            <v-row v-if="isPrepaid" class="mt-3">
              <v-col class="text-center">
                <v-card-text class="fs-2 text-white fw-bold text-center" style="padding-top:3px; padding-bottom: 8px;">79,00 KM</v-card-text>
                
                <v-chip v-if="(this.currentUserHardwares != null && this.currentUserHardwares.length != 0) && this.currentUserHardwares['0'].isAvailableNow == 0" style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                  <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                  <span class="fw-semibold fs-3">Naručen</span>
                </v-chip> 
                <v-btn v-else elevation="4" size="x-large" :disabled="isHardwareBtnDisabled()" @click="openHardwareDialog(1,1)" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-3 pr-4 pl-4 w-60">
                    <span class="font-semibold btn-text">Kupi uređaj</span>
                </v-btn> 
              </v-col>
            </v-row>
            <v-row v-else class="mt-3">
              <v-col v-if="!isPromo" class="text-center">
              <div v-if="(this.currentUserHardwares != null && this.currentUserHardwares.length != 0) && (this.currentUserHardwares['0'].isAvailableNow == 1 || this.currentUserHardwares['1'].isAvailableNow == 1 || this.currentUserHardwares['2'].isAvailableNow == 1)">
                <v-card-text v-if="this.selectedHardwarePaymentsNumId == null" class="fs-2 text-white fw-bold text-center" style="padding-top:3px; padding-bottom: 8px;">79,00 KM</v-card-text>
                <v-card-text v-else class="fs-2 text-white fw-bold text-center" style="padding-top:3px; padding-bottom: 8px;">{{ this.selectedHardwarePaymentsNum }}</v-card-text>
                <div v-if="this.selectedHardwarePaymentsNumId == null">
                  <v-btn elevation="4" size="x-large" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-3 pr-4 pl-4 w-60">    
                      <span class="font-semibold btn-text">Broja rata</span>
                      <v-icon size="large" class="pl-4">mdi-chevron-down-circle</v-icon>
                      <v-menu activator="parent">
                        <v-list style="background:white; margin:0; padding: 0;">
                          <v-list-item
                            class="text-center"
                            v-for="(item, index) in this.hardwarePaymentNums"
                            :key="index"
                            :value="index"
                            @click="selectHardwarePaymentsNumber(item.value)"
                            style="border-bottom: 0.1px dotted black;"
                            >
                            <v-list-item-title class="font-bold text-black">{{ item.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu> 
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn elevation="4" size="x-large" :disabled="isHardwareBtnDisabled()" @click="openHardwareDialogPostpaid(1, this.selectedHardwarePaymentsNumId)" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-3 pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Kupi uređaj</span>
                  </v-btn>
                  <v-btn v-if="this.selectedHardwarePaymentsNumId != null" @click="removeHardwareSelection()" color="black" icon="mdi-close-thick" class="ml-2"></v-btn>
                </div>
              </div>
              <div v-else>
                <v-card-text class="fs-2 text-white fw-bold text-center" style="padding-top:3px; padding-bottom: 8px;">79,00 KM</v-card-text>
                <v-chip style="margin: 8px; padding: 25px;" variant="elevated" elevation="2" color="success" size="x-large">
                  <v-icon class="pr-3 fs-1">mdi-check-circle</v-icon>
                  <span class="fw-semibold fs-3">Naručen</span>
                </v-chip>
              </div>
              </v-col>
              <v-col v-else class="text-center">
                <v-card-text class="fs-2 text-white fw-bold text-center" style="padding-top:3px; padding-bottom: 15px;">79,00 KM</v-card-text>
                <v-btn elevation="4" size="x-large" :disabled="true" class="bg-white text-2xl font-bold transition duration-700 ease-in-out hover:scale-105 mb-2 mt-3 pr-4 pl-4 w-60">
                      <span class="font-semibold btn-text">Kupi uređaj</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="this.addHardwareDialog" width="auto">
        <v-card>
          <v-card-title class="mt-2"><span class="font-semibold">Želiš kupiti uređaj?</span></v-card-title>
          <v-card-text>
            Klikom na dugme KUPI pokrećete narudžbu uređaja. <br> Da li ste sigurni da želite kupiti ovaj uređaj? <br> 
          </v-card-text>
          <v-card-actions class="ma-2">
            <v-spacer></v-spacer>
            <v-btn class="delete-btn" variant="tonal" @click="this.addHardwareDialog = false; this.loading = false"><span class="font-semibold">Otkaži</span></v-btn>                         
            <v-btn :loading="this.loading" class="delete-btn text-white" variant="flat" color="#b10d28" @click="this.orderHardware(this.currentHardwareId, this.currentHardwarePaymentsNum)"><span class="text-white font-semibold">Kupi uređaj</span></v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
  </v-container>
  </div>    
</template>

<script>
import {ref, onBeforeMount, onUnmounted} from 'vue'
import {useUserStore} from '../stores/UserStore';
import { useRouter } from 'vue-router'
import moment from 'moment';
export default {
  setup(){

    const router = useRouter();
    const userStore = useUserStore();
    let currentUserString = localStorage.getItem('user');
    let currentUser = ref(JSON.parse(currentUserString));

    let currentUserPackagesString = localStorage.getItem('packages');
    let currentUserPackages = JSON.parse(currentUserPackagesString);

    let currentUserHardwaresString = localStorage.getItem('hardwares');
    let currentUserHardwares = JSON.parse(currentUserHardwaresString);

    let currentUserPromoType = ref(localStorage.getItem('user_type'));

    let tab = ref(0);
    let loading = ref(false);
    let addSubError = ref(false);
    let addSubDialog = ref(false);
    let addHardwareDialog = ref(false);
    let addCardWarningDialog = ref(false);
    let btnClicked = ref(false);
    let addSubMessageSuccess = ref(false);
    let addHardwareMessageSuccess = ref(false);
    let addHardwareError = ref(false);
    let addHardwareErrorMessage = ref(null);
    let addSubMessageSuccessText = ref(null);
    const selectedPeriodId = ref(null);
    let periods = ref([
      {title: '30 dana / 33,00 KM', value: 1},
      {title: '15 dana / 25,00 KM', value: 2},
      {title: '7 dana / 12,00 KM', value: 3}
    ]);
    const selectedHardwarePaymentsNumId = ref(null);
    let hardwarePaymentNums = ref([
      {title: '79,00 KM / Jednokratno', value:1},
      {title: '79,00 KM / 12 rata', value: 2},
      {title: '79,00 KM / 24 rate', value: 3},
    ]);
    let stbOptions = ref([
      {title: 'PREPAID > Jednokratno', value: 1},
      {title: 'POSTPAID > 12 ili 24 rate', value: 2}
    ]);
    let selectedPeriod = ref(null);
    let selectedHardwarePaymentsNum = ref(null);
    const currentPackageId = ref(null);
    const currentHardwareId = ref(null);
    const currentHardwarePaymentsNum = ref(null);

    onBeforeMount(() => {
      window.scrollTo(0, 0);
    });

    onUnmounted(() => {
      localStorage.removeItem('user_type');
    });

    async function subscribe(packageId){

      this.loading = true;

      let response = await this.userStore.addSubscriptionById(packageId, this.currentUser.ott_email);

      if(response != null && response.result[0].result_code == 0){
          this.currentUser = JSON.parse(localStorage.getItem('user'));
          this.currentUserPackages = JSON.parse(localStorage.getItem('packages'));
          this.loading = false;
          this.addSubDialog = false;
          
          if(response.additional.UsedPromoCode == 1){
            this.addSubMessageSuccessText = 'Uspješno si aktivirao paket, naplaćen je iznos umanjen po promo kodu.';
          }else{
            this.addSubMessageSuccessText = 'Uspješno si se pretplatio.'
          }
          this.addSubMessageSuccess = true;

        }else{
          this.addSubError = true;
          this.addSubDialog = false;
          this.loading = false;
        }
    };

    async function orderHardware(hardwareId, numOfPayments){

      this.loading = true;

      let response = await this.userStore.orderHardwareById(this.currentUser.ott_email, hardwareId, numOfPayments);

      if(response != null && response.apiResult[0].result_code == 0){
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.currentUserHardwares = JSON.parse(localStorage.getItem('hardwares'));
        this.loading = false;
        this.addHardwareDialog = false;
        this.addHardwareMessageSuccess = true;
      }else{
        this.addHardwareError = true;
        this.addHardwareErrorMessage = response.resData;
        this.addHardwareDialog = false;
        this.loading = false;
      }

    }

    function openHardwareDialog(hardwareId, numOfPayments){
      this.currentHardwareId = hardwareId;
      this.currentHardwarePaymentsNum = numOfPayments;
      this.addHardwareDialog = true;

    }

    function openHardwareDialogPostpaid(hardwareId, numOfPayments){

      this.currentHardwareId = hardwareId;
      
      if(numOfPayments == 1){
        this.currentHardwarePaymentsNum = 1;
      }else if(numOfPayments == 2){
        this.currentHardwarePaymentsNum = 12;
      }else{
        this.currentHardwarePaymentsNum = 24;
      }

      this.addHardwareDialog = true;

    }

    function openDialog(packageId){
      this.currentPackageId = packageId;
      // console.log(this.currentPackageId);
      if(this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'PRE' && this.currentUser.card_info.length == 0 && (this.currentUser.highDeal_balance != null && this.currentUser.highDeal_balance[2] <= 0)){
        this.addCardWarningDialog = true;
      }else if(this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'UNI' && this.currentUserPromoType == 1 && (this.currentUser.highDeal_balance != null && this.currentUser.highDeal_balance[2] <= 0)){
        this.addCardWarningDialog = true;
      }else if(this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'UNI' && this.currentUserPromoType == 0){
        this.openPostpaidApplicationPage();
      }
      else{
        this.addSubDialog = true;
      }
    }

    function isDisabled(packageId){
      if(this.currentUser == null){
        return true;
      }
      else if(this.currentUser.crm_info.VcceState == null){
        return true;
      }else if(this.currentUser !== null && this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.includes('Suspended') && packageId != 1){
        return true;
      }//else if(this.currentUser.crm_info.VcceState.substring(0, 3) == 'PRE' && this.currentUser.card_info.length == 0){
        //return true;
      //}
      else if(this.currentUserPackages != null && (this.currentUserPackages[packageId].isPackageAllowed == 0 || this.currentUserPackages[packageId].isPackageActive == 1)){
        return true;
      }else if(this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'POS'){
        return false;
      }else{
        return false;
      }
    };

    function isMainDisabled(){
      if(this.currentUser == null){
        return true;
      }else if(this.currentUser.crm_info.VcceState == null){
        return true;
      }
      else if(this.currentUserPackages != null && this.currentUserPackages[1].isPackageActive == 1){
        return true;
      }else if(this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'POS'){
        return false;
      }else{
        return false;
      }
    }

    function isMainActive(){
      if(this.currentUserPackages['1'].isPackageActive == 1 || this.currentUserPackages['2'].isPackageActive == 1 || this.currentUserPackages['3'].isPackageActive == 1){
        return true;
      }else{
        return false;
      }
    }

    function isHardwareBtnDisabled(){
      //provjeravam da li je promo korisnik
      if(this.currentUser != null && this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'UNI'){
        return true;
      }else if(this.currentUserPackages['1'].isPackageActive == 0 && this.currentUserPackages['2'].isPackageActive == 0 && this.currentUserPackages['3'].isPackageActive == 0){
        return true;
      }
    }

    function showExpirationDate(packageId){
      if(this.currentUserPackages != null && this.currentUserPackages[packageId].getPackageExpDate != 'NO_DATA' && this.currentUserPackages[packageId].isPackageActive == 1){
        return true;
      }else{
        return false;
      }
    }

    function getMainExpDate(){
      let date = '';
      if(this.currentUserPackages['1'].getPackageExpDate != 'NO_DATA' && this.currentUserPackages['1'].isPackageActive == 1){
        date = this.currentUserPackages['1'].getPackageExpDate;

      }else if(this.currentUserPackages['2'].getPackageExpDate != 'NO_DATA' && this.currentUserPackages['2'].isPackageActive == 1){
        date = this.currentUserPackages['2'].getPackageExpDate;
      }else{
        date = this.currentUserPackages['3'].getPackageExpDate;
      }

      return date;
    }

    function getPackageStyle(packageId){

      if(this.currentUserPackages != null && this.currentUserPackages[packageId].isPackageActive == 1){
        return 'background: linear-gradient(to right, #346b29, #3a8030, #3f9638, #42ad40, #44c449);';
      }else{
        return 'background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));';
      }
      
    }

    // function isPackageDisabled(id){
    //   let packageId = id;
    //   if(this.currentUserPackages[packageId].isPackageAllowed == 0 || this.currentUserPackages[packageId].isInPromoPeriod == 1){
    //     return true;
    //   }else{
    //     return false;
    //   } 
    // }

    function  selectHardwarePaymentsNumber(payments_num){
      
      this.selectedHardwarePaymentsNumId = payments_num;

      switch(payments_num){
        case 1:
          this.selectedHardwarePaymentsNum = '79,00 KM / Jednokratno';
          break;
        case 2:
          this.selectedHardwarePaymentsNum = '79,00 KM / 12 rata';
          break;
        case 3:
          this.selectedHardwarePaymentsNum = '79,00 KM / 24 rate';
          break;
      }

    }

    function selectPeriod(period_id){

      this.selectedPeriodId = period_id;

      switch(period_id){
        case 1:
          this.selectedPeriod = '30 dana / 33,00 KM';
          break;
        case 2:
          this.selectedPeriod = '15 dana / 25,00 KM';
          break;
        case 3:
          this.selectedPeriod = '7 dana / 12,00 KM';
          break;
      }
      
    };

    function removeSelection(){
      this.selectedPeriod = null; 
      this.selectedPeriodId = null; 
      this.btnClicked = false;
    }

    function removeHardwareSelection(){
      this.selectedHardwarePaymentsNumId = null; 
      this.selectedHardwarePaymentsNum = null; 
      this.btnClicked = false;
    }

    function getActivePackagePrice(){

      if(this.currentUserPackages['1'].isPackageActive == 1){
        return this.currentUserPackages['1'].total_amount + ' KM / mjesec';
      }
      else if(this.currentUserPackages['2'].isPackageActive == 1){
        return this.currentUserPackages['2'].total_amount + ' KM / 15 dana';
      }
      else if(this.currentUserPackages['3'].isPackageActive == 1){
        return this.currentUserPackages['3'].total_amount + ' KM / 7 dana';
      }
      else{
        return "Paket po tvom";
      }

    }

    function openSignIn(){
      router.push({ name: 'SignInView'});
    }

    function openPostpaidApplicationPage(){
      router.push({name: 'PostpaidApplication'});
    }

    return{loading, currentUser, currentUserPromoType, userStore, addSubError, tab, addSubDialog, addHardwareDialog, addCardWarningDialog, addHardwareErrorMessage, addHardwareError, selectedHardwarePaymentsNumId, selectedHardwarePaymentsNum, addHardwareMessageSuccess, currentPackageId, currentHardwareId, currentHardwarePaymentsNum, currentUserPackages, currentUserHardwares, btnClicked, periods, hardwarePaymentNums, selectedPeriod, addSubMessageSuccess, addSubMessageSuccessText, selectedPeriodId, stbOptions, selectPeriod, subscribe, selectHardwarePaymentsNumber, orderHardware, openHardwareDialog, openSignIn, isDisabled, getPackageStyle, isMainDisabled, isMainActive, showExpirationDate, getMainExpDate, getActivePackagePrice, isHardwareBtnDisabled, openHardwareDialogPostpaid, openDialog, removeHardwareSelection, removeSelection, openPostpaidApplicationPage }
  },

  // created(){
  //   let currentUserPackagesString = localStorage.getItem('packages');
  //   this.currentUserPackages = JSON.parse(currentUserPackagesString);
  // }

  mounted(){
    let selectedUserType = this.$route.query.type;
    if(selectedUserType){
      if(selectedUserType == 'pre'){
        this.tab = 1;
      }
    }
  },

  computed:{

    biggerScreen(){
        if(this.$vuetify.display.width >= 960){
          return true;
        }else{
          return false;
        }
    },

    isPrepaid(){
      if(this.currentUser == null){
        return true;
      }else if(this.currentUser != null && this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'PRE'){
        return true;
      }else{
        return false;
      }
    },

    isPostpaid(){
      if(this.currentUser == null){
        return true;
      }else if(this.currentUser != null && this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'POS'){
        return true;
      }else{
        return false;
      }
    },

    isPromo(){
      if(this.currentUser != null && this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'UNI'){
        return true;
      }else{
        return false;
      }
    }

  }

}
</script>

<style>
  .packages{
    position: relative;
    height: 380px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top:0px; */
  }
  .packages::before{
    content: "";
    background-image: url('@/assets/images/banner.jpg');
    background-size: cover;
    background-position: 50% 20%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
  }

  .packages h1{
    font-family: 'Pero-Bold';
  }
  .packages p{
    font-family: 'Roboto-Light';
  }

  .packages-cards{
    /* position: relative; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* height: 900px; */
    /* background: rgb(0, 0, 0, 0.8); */
    background: rgb(0, 0, 0, 0.84);                   
    padding-top: 50px;
    padding-bottom: 140px;
    /* padding-left: 50px; */
  }                                                               

  @media only screen and (max-width: 600px){
    .packages-cards {
      padding-top: 30px;
      padding-bottom: 80px;
    }
  }

  /* .card{
    height: 800;
    width: 450;
  } */

  .card-title{
    font-family: 'Pero-Bold';
  }

  .card-subtitle{
    font-family: 'Roboto-Light';
    color: white;
  }

  .feature{
    font-family: 'Roboto-Bold';
    font-size: 18px;
    color: white;
    font-weight: 500;
    padding-top: 6px;
  }

  @media only screen and (max-width: 960px){
    .feature {
      font-size: 17px;
    }
  }

  .sub-card{
    height: max-content;
  }

  .btn-text{
    font-size: 22px;
  }

  .message{
    padding-bottom: 10px;
    font-family: 'Roboto-Bold';
    font-size: 16px;
  }

  .status-icon{
    background-color: rgb(37, 250, 37);
    width: 33px !important;
    height: 33px !important;
    border-radius: 33px;
  }

  @media only screen and (max-width: 1132px){
    .status-icon {
      width: 25px !important;
      height: 25px !important;
      border-radius: 33px;
    }
  }

  @media only screen and (max-width: 500px){
    .status-icon {
      width: 20px !important;
      height: 20px !important;
      border-radius: 33px;
    }
  }

  .tab-item{
    font-size: 18px;
    font-weight: 700;
  }

  .subscribe-btn{
    display: flex;
    justify-content: center;
  }

  .stb-image{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
</style>