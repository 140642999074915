<template>
  <v-footer fixed class="bg-black text-center justify-center pt-8 footer mt-auto">
    <v-container fluid>
      <v-row justify="center">
        <v-col xs="12" sm="12" md="4" lg="4" style="margin-top:8px" v-if="this.$vuetify.display.width >= 960">
          <ul style="list-style-type:none">
            <li>
              <router-link class="no-underline" :to="{name: 'PackagesView'}">
              <a class="page-links"><span class="text-xl font-bold hover:text-red-700 transition duration-700 ease-in-out">Paketi i uređaji</span></a>
              </router-link>
            </li>
            <li>
              <router-link class="no-underline" :to="{name: 'ChannelsView'}">
              <a class="page-links"><span class="text-xl font-bold hover:text-red-700 transition duration-700 ease-in-out">TV kanali</span></a>
              </router-link>
            </li>
            <li>
              <router-link class="no-underline" :to="{name: 'VideosView'}">
              <a class="page-links"><span class="text-xl font-bold hover:text-red-700 transition duration-700 ease-in-out">Videoteke</span></a>
              </router-link>
            </li>
            <li>
              <router-link class="no-underline" :to="{name: 'ContactUs'}">
              <a class="page-links"><span class="text-xl font-bold hover:text-red-700 transition duration-700 ease-in-out">Kontakt</span></a>
              </router-link>
            </li>
            <!-- <li>
              <router-link class="no-underline" :to="{name: 'ProfileView'}">
              <a class="page-links"><span class="text-lg font-bold hover:text-red-700 transition duration-700 ease-in-out">Nalog</span></a>
              </router-link>
            </li> -->
            <!-- <li><a href="/kanali" class="page-links"><span class="text-lg font-bold hover:text-red-800 transition duration-700 ease-in-out">TV kanali</span></a></li>
            <li><a href="/videoteke" class="page-links"><span class="text-lg font-bold hover:text-red-800 transition duration-700 ease-in-out">Videoteke</span></a></li>
            <li><a href="/nalog" class="page-links"><span class="text-lg font-bold hover:text-red-800 transition duration-700 ease-in-out">Nalog</span></a></li> -->
          </ul>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="4" lg="4" class="mt-3">
          <p class="text-xl font-bold">Kontaktiraj podršku</p> 
          <a href="mailto: podrska@movetv.ba" class="page-links"><span class="text-xl font-bold hover:text-red-700 transition duration-700 ease-in-out">podrska@movetv.ba</span> </a> <br>
          <span class="text-xl font-bold">0800 30 630</span> <br>
          <span @click="dialog3 = true" class="cursor-pointer font-bold hover:text-blue-400 transition duration-700 ease-in-out pr-4 text-lg"><v-icon class="mr-1 ml-5" size="small" color="info" icon="mdi-help-circle"></v-icon>Česta pitanja</span> <br>
          <span @click="dialog4 = true" class="cursor-pointer font-bold hover:text-orange-400 transition duration-700 ease-in-out pr-4 text-lg"><v-icon class="mr-1 ml-5 mb-1" size="small" color="orange" icon="mdi-help-circle"></v-icon>Načini plaćanja</span>   
                  
        </v-col>
        <v-col v-else cols="12" class="mt-1">
          <p class="text-2xl font-bold">Kontaktiraj podršku</p> 
          <a href="mailto: podrska@movetv.ba" class="page-links"><span class="text-2xl font-bold">podrska@movetv.ba</span> </a> <br>
          <span class="text-2xl font-bold">0800 30 630</span> <br>
          <span @click="dialog3 = true" class="cursor-pointer font-bold pr-4 text-xl"><v-icon class="ml-4 mr-1" size="x-small" color="orange" icon="mdi-help-circle"></v-icon>Česta pitanja</span> <br>
          <span @click="dialog4 = true" class="cursor-pointer font-bold pr-4 text-xl"><v-icon class="ml-4 mr-1 mb-1" size="x-small" color="info" icon="mdi-help-circle"></v-icon>Načini plaćanja</span>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="4" lg="4" class="mt-3">
          <p class="text-xl font-bold">Preuzmi aplikaciju:</p> 
          <v-row justify="center" class="mt-1">
           <a href="https://play.google.com/store/apps/details?id=com.mtssi.supernova" target="_blank" class="page-links pr-2"><img class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 duration-300" src="../assets/images/google-play-icon.svg" width="40" height="40"></a>
           <a href="https://apps.apple.com/my/app/supernovaiptv/id1628850139" target="_blank" class="page-links pr-2"><img class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 duration-300" src="../assets/images/app-store.svg" width="40" height="40"></a>
           <a href="https://appgallery.huawei.com/app/C109761403" target="_blank" class="page-links"><img class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-100 duration-300" src="../assets/images/appgallery.svg" width="40" height="40"></a>
          </v-row>
          <p class="text-xl font-bold mt-8">Sigurno plaćanje:</p>
          <v-row justify="center" class="mb-1"><a href="https://sbon.ba" target="_blank" class="bank-links"><img class="sbon-logo" src="../assets/images/sbon-logo.png"></a></v-row>
          <v-row justify="center" class="mt-1"> 
              <a href="https://www.mastercard.ba/bs-ba.html" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/mastercard.svg" ></a>
              <a href="https://www.mastercard.ba/bs-ba/consumers/find-card-products.html" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/maestro.svg"></a>
              <a href="https://www.mastercard.ba/bs-ba/issuers/identity-check.html" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/idcheck.svg"></a>
              <a href="https://www.visaeurope.com" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/visa.jpg" width="40" height="40"></a>
              <a href="https://www.visaeurope.com/making-payments/verified-by-visa/" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/visaSecure.svg"></a>
          </v-row>
        </v-col>
        <v-col v-else cols="12" class="mt-1">
          <p class="text-2xl font-bold">Preuzmi aplikaciju:</p>
          <v-row justify="center" class="mt-1">
           <a href="https://play.google.com/store/apps/details?id=com.mtssi.supernova" target="_blank" class="page-links pr-2"><img src="../assets/images/google-play-icon.svg" width="40" height="40"></a>
           <a href="https://apps.apple.com/my/app/supernovaiptv/id1628850139" target="_blank" class="page-links pr-2"><img src="../assets/images/app-store.svg" width="40" height="40"></a>
           <a href="https://appgallery.huawei.com/app/C109761403" target="_blank" class="page-links pr-2"><img src="../assets/images/appgallery.svg" width="40" height="40"></a>
          </v-row>
          <p class="text-xl font-bold mt-8">Sigurno plaćanje:</p>
          <v-row justify="center" class="mb-1"><a href="https://sbon.ba" target="_blank" class="bank-links"><img class="sbon-logo" src="../assets/images/sbon-logo.png"></a></v-row>
          <v-row justify="center" class="mt-1">
              <a href="https://www.mastercard.ba/bs-ba.html" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/mastercard.svg" ></a>
              <a href="https://www.mastercard.ba/bs-ba/consumers/find-card-products.html" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/maestro.svg"></a>
              <a href="https://www.mastercard.ba/bs-ba/issuers/identity-check.html" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/idcheck.svg"></a>
              <a href="https://www.visaeurope.com" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/visa.jpg" width="40" height="40"></a>
              <a href="https://www.visaeurope.com/making-payments/verified-by-visa/" target="_blank" class="bank-links"><img class="bank-logo" src="../assets/images/visaSecure.svg"></a>
          </v-row>
          <!-- <a href="https://www.facebook.com/mojasupernovabih" target="_blank" class="page-links"><v-icon size="x-large" class="mr-2 text-blue-500" icon="mdi-facebook"></v-icon></a>
          <a href="https://www.instagram.com/mojasupernovabih" target="_blank" class="page-links"><v-icon size="x-large" class="mr-2 text-pink-500 " icon="mdi-instagram"></v-icon></a>
          <a href="https://www.youtube.com/@mojasupernovabih" target="_blank" class="page-links"><v-icon size="x-large" class="mr-2 text-red-500" icon="mdi-youtube"></v-icon> <br></a> -->
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-13 justify-center"> 
        <v-col v-if="biggerScreen" xs="12" sm="6" md="5" lg="5">
          <v-btn variant="text" size="small" @click="dialog = true"><span class="hover:text-red-700 transition duration-700 ease-in-out text-xs font-bold">Uslovi korištenja</span></v-btn>
          <v-btn variant="text" size="small" @click="dialog2 = true"><span class="hover:text-red-700 transition duration-700 ease-in-out text-xs font-bold">Uslovi plaćanja i politika privatnosti</span></v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn variant="text" size="small" @click="dialog = true"><span class="hover:text-red-700 transition duration-700 ease-in-out text-xs font-bold">Uslovi korištenja</span></v-btn>
          <v-btn variant="text" size="small" @click="dialog2 = true"><span class="hover:text-red-700 transition duration-700 ease-in-out text-xs font-bold">Uslovi plaćanja i politika privatnosti</span></v-btn>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="6" md="5" lg="5">
         <p class="text-base font-extrabold"> Blicnet d.o.o. <span class="text-sm font-semibold text-white"> Sva prava zadržana.</span></p> 
        </v-col>
        <v-col v-else cols="12">
         <p class="text-base font-extrabold"> Blicnet d.o.o. <span class="text-sm font-semibold text-white"> Sva prava zadržana.</span></p> 
        </v-col>
        <v-col cols="1" class="up-arrow" v-if="this.$vuetify.display.width >= 960">
          <a href="#" class="no-underline"><v-icon size="x-large" class="text-red-700 hover:text-white transition duration-700 ease-in-out animate-bounce">mdi-arrow-up-thin-circle-outline</v-icon></a>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
    <v-card class="bg-gray-200 dialog-terms">
      <v-toolbar
          dark
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        >
          <v-toolbar-title class="text-white" style="font-size: 18px;">Uslovi korištenja</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              variant="text"
              @click="dialog = false"
            >
              <v-icon size="large" color="white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <p class="pa-10">
          <span class="font-bold">Uvod</span> <br><br>
          Uslovima korištenja MOVE TV usluge (u daljem tekstu: Uslovi) uređuju se međusobni odnosi u vezi sa korištenjem MOVE TV usluge između Blicnet d.o.o. Banja Luka (u daljem tekstu: Blicnet ) i korisnika MOVE TV usluge (u daljem tekstu: korisnik). <br>
          MOVE TV usluga je usluga kojom se, putem MOVE aplikacije, omogućava pristup određenom programskom sadržaju na IPTV platformi putem otvorene mreže, preko javnog interneta, sa mogućnošću korištenja dodatnih TV usluga kao što su: gledaj propušteno, gledaj ispočetka, pauziraj TV i video na zahtjev. <br><br>
          Osnovni uslovi za korištenje MOVE TV usluge su: <br>
          •	da korisnik posjeduje odgovarajući terminalni uređaj (smart TV, tablet ili mobilni telefon) i <br>
          •	da ima pristup internetu (putem WiFi ili mobilne mreže).<br>
          Ukoliko korisnik želi da pristupa programskom sadržaju MOVE TV usluge putem STB-a (SetTopBox) na velikom ekranu, a ne posjeduje smart TV, ima mogućnost da kupi odgovarajući STB po cijeni shodno Cjenovniku.<br>
          Korištenje MOVE TV usluge je omogućeno svim fizičkim licima na teritoriji Bosne i Hercegovine. 
          Plaćanje naknade za korištenje MOVE TV usluge je omogućeno kroz postpaid ili prepaid način plaćanja. <br>
          Naknade za korištenje MOVE TV usluge su definisane Cjenovnikom korištenja MOVE TV usluge, koji je dostupan na web stranici <a class="no-underline text-red-600" href="www.supernovabih.ba" target="_blank">www.supernovabih.ba</a> i na Supernova  prodajnim mjestima. <br><br>
          Pored osnovne usluge pristupa TV sadržaju, MOVE TV usluga omogućava i korištenje dodatnih TV usluga: <br>
          •	<b>Gledaj propušteno</b> (eng. Catch -up), dodatna usluga koja omogućava naknadno gledanje propuštenog sadržaja na unaprijed određenom broju TV kanala u prethodna 3 dana. Sadržajima kanala na kojima je omogućena ova usluga korisnici će moći da pristupe kroz TV vodič. Sadržaj, film ili emisiju koju je odabrao da pogleda korisnik će moći i da premotava unaprijed, unazad ili da zaustavi emitovanje; <br>
          •	<b>Pauziraj TV</b> (eng. Pause TV), dodatna usluga koja omogućava korisnicima da gledaju svoje omiljene emisije u za njih pogodnijem trenutku, nego što je originalno vrijeme prikazivanje emisija. Za vrijeme gledanja TV emisija pomjerenih u odnosu na originalno vrijeme prikazivanja korisnik može da premotava emisiju unaprijed ili unazad i da zaustavi emitovanje; <br>
          •	<b>Gledaj ispočetka</b> (en. Start over), dodatna usluga koja korisnicima omogućava da u trenutku gledanja određenog programskog sadržaja vrate taj sadržaj na početak emitovanja. <br>
          Dodatne TV usluge su dostupne korisnicima MOVE TV usluge u okviru naknade za korištenje MOVE TV usluge i aktiviraju se automatski na dan aktiviranja MOVE TV usluge. <br><br>
          <span class="font-bold">Videoteke</span><br><br>
          SVoD (Subscription Video on Demand) usluga: <br>
          SVoD usluga je usluga videa na zahtjev koja korisnicima MOVE TV usluge daje mogućnost gledanja filmskog i serijskog sadržaja različitih žanrova u okviru videoteke uz mogućnost neograničenog broja pregleda odabranog sadržaja u periodu korištenja usluge. <br><br>
          <span class="font-bold">VoD (Video on Demand)</span><br> <br>
          <b>Video na zahtjev</b> – je usluga koja korisnicima omogućava iznajmljivanje pojedinih video sadržaja (filmovi, serije, koncerti i sl.) u okviru videoteka, uz mogućnost pristupa iznajmljenom sadržaju tokom određenog perioda sati/dana od iznajmljivanja. <br>
          Naknada za korištenje Videoteka je definisana Cjenovnikom usluga, a korištenje Opštim uslovima poslovanja Blicnet-a. <br> <br>
          <span class="font-bold">Aktivacija/deaktivacija dodatnih paketa TV kanala i videoteka</span><br> <br>
          Aktivaciju/deaktivacija dodatnih usluga, paketa TV kanala ili videoteka, MOVE TV korisnici mogu izvršiti putem web self care portala u okviru internet stranice www.movetv.ba ili putem posebne aplikacije namijenjene za upravljanje uslugama. <br><br>
          <span class="font-bold">Registracija MOVE TV usluge i način plaćanja</span><br> <br>
          <span class="text-blue-800">Prepaid registracija </span> <br> <br>
          Za korištenje MOVE TV usluge uz prepaid način plaćanja (plaćanje unaprijed), potrebno je da korisnik putem web portala www.movetv.ba izvrši registraciju. Prilikom registracije potrebno je da korisnik pravilno unese svoje ime i prezime, korisničko ime (e-mail adresa koju koristi) i generiše svoju lozinku za pristup. <br>
          Nakon registracije potrebno je da korisnik odabere željenu tarifnu opciju MOVE TV usluge i pokrene proces online plaćanja. Plaćanje odabranog perioda korištenja MOVE TV usluge omogućeno je putem platnih kartica kao i drugih načina plaćanja koji će biti dostupni korisnicima i objavljeni putem web portala www.movetv.ba <br>
          Prilikom plaćanja putem platnih kartica MASTERCARD, MAESTRO i VISA, svi podaci o platnoj kartici se enkriptovano razmjenjuju direktno između korisnika i e-commerce sistema Banke koja vrši prihvat kartice. Sigurnost, odnosno tajnost kartice garantuju i obezbjeđuju Banka i Internet Payment Service Provider koji je registrovani pružalac e-commerce usluge od strane nadležnih finansijskih institucija pri globalnim platnim sistemima MasterCard/ Visa. <br>
          Po prijemu potvrde o uspješnom plaćanju MOVE TV usluge, što se smatra trenutkom aktivacije MOVE TV usluge, korisnik se upućuje na MOVE aplikaciju ili Google Play/App store/App Gallery za preuzimanje i instalaciju MOVE aplikacije. Korisnik u okviru MOVE aplikacije unosi svoje podatke za registraciju (korisničko ime i lozinku) koji se koriste za prijavu. <br>
          Prilikom aktivacije MOVE TV usluge omogućena je registracija maksimalno 3 (tri) uređaja za istovremeno gledanje TV kanala dostupnih u okviru MOVE TV usluge. Korisnik ima mogućnost da zamijeni jedan od već registrovanih uređaja za gledanje TV kanala dostupnih u okviru MOVE TV usluge, putem sekcije Moj nalog na web portalu movetv.ba. Naknada za odabranu tarifnu opciju MOVE TV usluge se naplaćuje od dana aktivacije usluge, prema Cjenovniku usluga. <br>
          Korištenje MOVE TV usluge na period od 30 dana podrazumijeva aktivaciju usluge uz automatsko produžavanje svaki naredni mjesec sve dok korisnik ne izvrši deaktivaciju MOVE TV usluge putem web portala movetv.ba. U slučaju nemogućnosti automatske naplate naknade za period korištenja MOVE TV usluge u trajanju od 30 dana za naredni pretplatni period, korisniku će biti onemogućeno korištenje usluge. Za dalje korištenje usluge korisnik mora inicirati uplatu, kao i prilikom inicijalnog plaćanja i aktivacije usluge. <br>
          Korištenje MOVE TV usluge na period kraći od 30 dana ne podrazumijeva automatsko produžavanje za naredni period već korisnik mora sam da inicira plaćanje. <br> <br>
          <span class="text-blue-800">Postpaid registracija </span> <br> <br>
          Za korištenje MOVE TV usluge uz postpaid način plaćanja potrebno je da korisnik zaključi ugovor sa Blicnet-om. Prilikom zaključenja ugovora korisniku će biti dodijeljeni korisničko ime i lozinka za pristup MOVE aplikaciji koji su potrebni za registraciju usluge. <br>
          Mjesečna naknada za korištenje MOVE TV usluge uz postpaid način plaćanja je definisana Cjenovnikom Blicnet-a za koju će se korisniku izdavati računi sa valutom plaćanja. Prava i obaveze korisnika MOVE TV usluge sa postpaid modelom plaćanja, kao i drugi uslovi korištenja usluga, regulisana su posebnim ugovorom koji korisnik zaključuje sa Blicnet-om i Opštim uslovima poslovanja Blicnet-a. <br> <br>
          <span class="text-blue-800">Deaktivacija usluge </span> <br> <br>
          Kada korisnik MOVE TV usluge uz prepaid način plaćanja zahtjeva deaktivaciju MOVE TV usluge putem web portala movetv.ba, deaktivacija usluge će se izvršiti zaključno sa završetkom trajanja perioda od 30 dana za koji je korisnik pretplaćen. U tom slučaju, korisniku će biti omogućeno korištenje MOVE TV usluge do kraja perioda važenja. <br>
          Način deaktivacije i uslovi raskida ugovora o korištenju MOVE TV usluge uz postpaid način plaćanja su definisani Opštim uslovima poslovanja Blicnet-a. <br> <br>
          <span class="font-bold">AUTENTIFIKACIJA</span><br><br>
          Za potrebe korištenja MOVE TV usluge korisnik dobija lični identifikacioni broj (engl. Personal Identification Number, u daljem tekstu: PIN). Korisnik može izmijeniti inicijalno mu dodijeljeni PIN. Korisnik će biti upozoren na mogućnost zloupotrebe PIN-a od strane trećih lica, i preuzima potpunu obavezu za njegovu upotrebu i dužan je da isti čuva u tajnosti. <br>
          Upotrebom PIN-a korisnik može: <br>
          • podešavati sve postavke usluge, <br>
          • aktivirati roditeljsku kontrolu, <br>
          • aktivirati dodatne usluge <br>
          • vršiti autorizaciju promjena postavki. <br>
          MOVE TV usluga nudi mogućnost korištenja dodatne usluge roditeljske kontrole sadržaja, koja omogućava ograničavanja pristupa i kontrolu pregledavanja sadržaja koji nije primjeren djeci I maloljetnicima ili transakcijama, kao što je naručivanje video sadržaja, a prema procjeni roditelja. <br><br>
          <span class="text-blue-800">Ostale odredbe o korištenju usluge </span> <br> <br>
          Korisnicima koji pristupaju MOVE TV usluzi putem mobilne mreže mtel-a, uključena je neograničena količina podataka za korištenje MOVE TV usluge, dok će korisnicima koji pristupaju MOVE TV usluzi putem usluge pristupa Internetu koji obezbjeđuju drugi operatori u BiH, utrošeni internet saobraćaj biti naplaćen od strane drugih operatora po njihovim definisanim cijenama i uslovima korištenja internet saobraćaja. <br>
          Ukoliko korisnik pristupa MOVE TV usluzi putem usluge pristupa Internetu koji obezbjeđuju drugi operatori u BiH, Blicnet neće biti odgovoran za bilo koju štetu koja nastane kao posljedica prekida Internet konekcije prilikom korištenja MOVE TV usluge. <br>
          Perfomanse i kvalitet slike zavise od operativnog sistema uređaja preko kojih se koristi MOVE aplikacija, brzine internet usluge koja se koristi, kvaliteta i opterećenja WiFi mreže. Za korištenje MOVE TV usluga na Smart TV-u ili mobilnom uređaju, potreban je minimalan protok od 3Mbps za SD, odnosno 5Mbps za HD sadržaj. Za postizanje optimalnih perfomansi i praćenje servisa sa najboljim kvalitetom slike, preporučena brzina protoka je od 5 Mbps za SD i 10 Mbps za HD sadržaj. <br>
          Korisnik je u obavezi da poštuje pozitivne pravne propise i međunarodno pravo u pogledu zaštite autorskih prava i prava industrijskog vlasništva. Zabranjeno je svako neovlašćeno snimanje, javno objavljivanje i distribuisanje programskih sadržaja koji su korisniku dostupni korištenjem MOVE TV usluge i dodatnih usluga, kao i svaki drugi oblik iskorištavanja istih u bilo koju svrhu osim za ličnu upotrebu. <br>
          U slučaju da usljed ponašanja korisnika dođe do pokretanja bilo kakvog postupka pred pravosudnim i drugim nadležnim organima, korisnik je u obavezi da preuzme cjelokupnu odgovornost za učinjenu povredu autorskih prava i prava industrijske svojine i da Blicnetu nadoknadi cjelokupnu štetu prouzrokovanu u vezi sa tim. Blicnet ne snosi bilo kakvu odgovornost za privremena, moguća, zatamnjenja određenih dijelova televizijskih programa, koje je dužan izvesti u skladu sa zahtjevima programskih dobavljača ili zakonskim odredbama. <br>
          Blicnet nije odgovoran za sadržaj i formu koje se emituju kao sadržaj u okviru MOVE TV usluge, te ne provjerava čini li se sadržajem povreda nekog od zakonom zaštićenih prava, što uključuje, ali nije ograničeno na prava sadržana u propisima o autorskim i srodnim pravima, propisima o pravu industrijskog vlasništva, propisima o medijima, te propisima o zaštiti potrošača. <br>
          Blicnet ne snosi bilo kakvu odgovornost za štetu koja bi mogla nastati objavljivanjem sadržaja kojim se čini povreda nekog od zakonom zaštićenih prava. Blicnet nije odgovoran za bilo koju štetu koja bi mogla nastati korisnicima ili trećim licima kao posljedica netačnih, nepotpunih ili nepostojećih informacija o sadržajima programa ili vremenima emitovanja istih. <br>
          Zbog ograničenja propisanih politikama “prodavnica” aplikacija (Google Play, App store, App Gallery, Samsung Smart Hub, LG Content Store i drugi) na kojima su dostupne aplikacije za smart uređaje, sadržaji za odrasle ili drugi koji su po tim politikama zabranjeni, neće biti dostupni putem MOVE TV usluge. <br>
          Blicnet nije odgovoran za nepostojanje oznake sadržaja za odrasle, odnosno pogrešne ocjene sadržaja i njegove kategorizacije (eng. Parental Guidance), te za posljedice koje bi mogle nastati ukoliko postoji mogućnost da bi prikazani program mogao ugroziti fizički, mentalni i moralni razvoj maloljetnika, a ne sadrži odgovarajuća upozorenja ili se prikazivao u neprimjereno vrijeme. <br>
          U slučaju da se putem MOVE TV usluge vrši distribucija kanala sa sadržajem za odrasle, odnosno reality i pseudoreality sadržajem, takvi kanali biće zaštićeni odgovarajućom tehničkom zaštitom, a korisnicima će biti obezbijđeni podaci za pristup. Blicnet nije odgovoran za nemogućnost distribucije sadržaja ukoliko je nemogućnost posljedica više sile ili drugog događaja koji je izvan kontrole i nezavisno od volje Blicnet-a. <br>
          Blicnet zadržava pravo da mijenja raspored programa u paketima na način da mijenja postojeće programe novim, uvodi nove programe uz postojeće ili ukida pojedine postojeće programe, te dodaje nove pakete TV programa. Blicnet neće bezrazložno mijenjati raspored programa ako bi to uzrokovalo značajnu neravnotežu u pravima i obavezama ugovornih strana na štetu korisnika, osim u slučajevima kada je promjena tehničkih, ekonomskih ili pravnih uslova prijema i distribucije programa razlog za izmjenu rasporeda programa. Blicnet može bilo kada prestati distribuisati pojedini program ako mu dobavljač programa na bilo koji pravno dopušten način onemogući distribuciju programa. <br>
          Blicnet nije odgovoran za štetu koja može nastati korisniku i/ili trećem licima zbog neovlašćenog korištenja platne kartice odnosno bilo koje zloupotrebe iste, uključujući krađu, korištenje bez znanja vlasnika platne kartice i sl. i u navedenim slučejevima Blicnet neće izvršiti povrat uplaćenih sredstava. <br>
          Blicnet nije odgovoran za postupanje Banke izdavaoca platne kartice i bilo kakav vid štete koju korisnik pretrpi u vezi sa uslugama te Banke ili uslova korištenja njenih usluga. Blicnet nije odgovoran za postupanje Banke ili bilo kakvu štetu koju korisnik pretrpi u vezi sa uslugama Banke, a koji su u odgovornosti Banke. Sve smetnje u vezi korištenja MOVE TV usluge, korisnici mogu prijaviti putem web portala movetv.ba, putem e-mail adrese: podrska@movetv.ba ili pozivom na besplatni info broj 0800 30 630. <br>
          Blicnet zadržava pravo da u skladu sa važećim zakonskim i podzakonskim propisima izmjeni ove Uslove korištenja, o čemu će na odgovarajući način obavještavati korisnika. Prihvatanjem ovih Uslova korištenja i registracijom MOVE TV usluge, smatraće se da je korisnik zaključio ugovor o korištenju MOVE TV usluge. Za sve što nije definisano ovim Uslovima korištenja, primjenjivaće se Opšti uslovi poslovanja Blicnet d.o.o. Banja Luka.
        </p>
    </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog2"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
    <v-card class="bg-gray-200 dialog-payment">
      <v-toolbar
          dark
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        >
          <v-toolbar-title class="text-white" style="font-size: 18px;">Uslovi plaćanja</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              variant="text"
              @click="dialog2 = false"
            >
              <v-icon size="large" color="white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <p class="pa-10">
          <span class="font-bold">Uslovi plaćanja i politika privatnosti</span> <br><br>
          Plaćanje MOVE TV usluge realizovan kao prepaid servis možete obaviti vašim MasterCard, Maestro i Visa platnim karticama. Plaćanje MOVE TV usluge realizovan kao postpaid servis možete obaviti uplatom na račun Blicnet d.o.o. kod neke od komercijalnih banaka, a na osnovu ispostavljenog računa. <br><br>
          <span class="font-semibold">Plaćanje prepaid servisa</span> <br><br>
          Za korištenje tarifnih opcija MOVE TV paketa na 7 ili 15 dana neophodna je platna kartica koju je potrebno registrovati, dok se plaćanje vrši jednokratno za izabrani peirod korišćenja. Nakon verifikacije uplate, korisniku će biti dostupni kanali bez automatske periodične naplate. <br>
          Za korištenje tarifnih opcija MOVE TV paketa na mjesec dana neophodna je platna kartica koju je potrebno registrovati, dok se plaćanje vrši jednokratno za izabrani peirod korišćenja. Nakon verifikacije uplate, korisniku će biti dostupni kanali uz automatsku periodičnu naplatu za naredni period. <br>
          Kod registracije platne kartice, a u svrhu sigurnosne provjere, korisniku može biti naplaćen i refundiran iznos u zavisnosti od kartične organizacije i politike korištenja Banke čiju kartcu korisnik registruje. Vrijeme potrebno za refundiranje ovog iznosa zavisi od Vaše banke. <br>
          Prilikom dodavanja Vaše platne kartice potrebno je da unesete lične podatke i podatke sa kartice: broj kartice, datum isteka i kontrolni broj (CVV). Kontrolni broj je trocifreni ili četvorocifreni broj otisnut na poleđini kartice i služi kao dokaz da fizički posjedujete karticu u trenutku online kupovine i smanjuje mogućnost zloupotrebe. <br>
          Nakon registracije kartice, automatski će se izvršiti naplata naknade sa odabrane platne kartice jednom mjesečno prilikom obnove pretplate za MOVE TV uslugu. Obnova pretplate i naplata će se vršiti automatski sve do odjave usluge. <br>
          Potvrdom plaćanja korisnik prihvata sljedeće uslove vezano za automatsku periodičnu naplatu: <br><br>
              •	daje saglasnost da naknada za korišćenje MOVE TV usluge bude automatski periodično naplaćena sa korisnikovog računa za naredni pretplatni period, <br>
              •	automatska naplata će biti realizovana na početku svakog pretplatnog perioda za taj period, <br>
              •	ukoliko naplata pretplate za izabranu uslugu ne uspije u prvom pokušaju, pokušaji naplate biće ponovljeni nekoliko puta. Ako naplata ne uspije, pretplatni period neće biti produžen, a pružanje usluge će biti obustavljeno,<br>
              •	otkazivanje pretplate će obustaviti dalju automatsku periodičnu pretplatu,<br>
              •	pretplatu na uslugu korisnik može otkazati u bilo kom trenutku izborom opcije “Deaktiviraj MOVE TV” na portalu usluge u meniju o korisničkim podacima koji se otvara klikom na nalog u gornjem desnom uglu portala,<br>
              •	ukoliko korisnik deaktivira uslugu, uslugu će moći koristiti do datuma isteka plaćene pretplate,<br>
              •	korišćenje MOVE TV usluge je definisano “Posebnim uslovima korištenja Move TV usluge” koji se mogu pronaći klikom na <a class="text-red-700 no-underline font-bold" href="https://www.supernovabih.ba/wp-content/uploads/2024/04/Posebni-uslovi-koristenja-MOVE-TV-usluge-2024-04-13.pdf" target="_blank">link.</a><br><br>
          Za neke kartice su implementirani dodatni metodi zaštite (3D Secure) kod kojih Vam banka izdavalac u svrhu potvrde transakcije šalje autorizacioni kod SMS porukom. <br>
          Više informacija o procesu plaćanja možete pronaći u nastavku. <br><br>
          <span class="font-bold">Sigurnost plaćanja</span> <br><br>
          Tajnost Vaših podataka je zaštićena i osigurana upotrebom SSL enkripcije. Stranice za naplatu putem interneta osigurane su korištenjem Secure Socket Layer (SSL) protokola sa 128-bitnom enkripcijom podataka. SSL enkripcija je postupak šifrovanja podataka radi sprječavanja neovlaštenog pristupa prilikom njihovog prenosa. <br>
          Time je omogućen siguran prenos informacija te onemogućen nedozvoljen pristup podacima prilikom komunikacije između korisnikovog računara i WebPay servisa, te obratno. <br>
          WebPay servis i finansijske ustanove razmjenjuju podatke upotrebom virtualne privatne mreže (VPN), koja je zaštićena od neautorizovanog pristupa. <br>
          arsBlue Asoft IPG je sertifikovan prema PCI DSS Level 1 sigurnosnom standardu propisanom Visa i Mastercard pravilima. <br>
          Blicnet d.o.o. ne pohranjuje brojeve kreditnih kartica i brojevi nisu dostupni neovlaštenim osobama. <br><br>
          <span class="font-bold">Uslovi plaćanja</span> <br><br>
          <span class="font-semibold">Podaci o plaćanju</span> <br>
          Naziv: Blicnet d.o.o. Banja Luka <br>
          JIB: 4400999050002 <br>
          PIB: 400999050002 <br>
          Adresa: Vuka Karadžića 2, 78000 Banja Luka, BiH <br>
          Info telefon: 0800 30 630 <br>
          E-mail: podrska@movetv.ba <br><br>
          Ovim se uslovima utvrđuje postupak naručivanja, plaćanja, isporuke te reklamacija proizvoda ili usluga koji su ponuđeni kroz ovu aplikaciju. <br>
          Aplikacija se može koristiti za Vašu privatnu upotrebu bez ikakvih naknada za korištenje, a prema sljedećim uslovima i pravilima: Oprator je Blicnet d.o.o. Banja Luka, a kupac je korisnik ove aplikacije koji popuni elektronsku narudžbu, pošalje je operatoru te izvrši plaćanje putem kreditnih kartica ili potpiše ugovor sa operatorom za korištenje MOVE TV servisa za šta će mu se izdavati mjesečni računi. <br> <br>
          <span class="font-bold">Plaćanje</span> <br><br>
          Odabrane usluge realizovane kao prepaid servis se plaćaju online jednom od kreditnih kartica: Mastercard, Maestro ili Visa. <br>
          Plaćanje MOVE TV usluge realizovan kao postpaid servis mogu se obaviti uplatom na račun Blicnet d.o.o. kod neke od komercijalnih banaka, a na osnovu ispostavljenog računa. <br><br>
          <span class="font-bold">Reklamacije</span> <br><br>
          Blicnet d.o.o.  Banja Luka se obavezuje da će isporučiti uslugu koju je korisnik odabrao i kupio putem aplikacije MOVE TV. U slučaju greške ili neispravnog isporučenja odabrane usluge korisnik je obavezan prijaviti smetnje u isporuci servisa na mejl ili telefonom kako bi smetnja bila otklonjena u što kraćem roku.  <br>
          Za prijavu smetnji kontaktirajte podršku na e-mail podrska@movetv.ba ili pozovite Kontakt centar na 0800 30 630. <br> <br>
          <span class="font-bold">Posebne odredbe</span> <br><br>
          „Blicnet d.o.o. Banja Luka“ zadržava pravo na izmjenu ovih uslova i pravila. Sve promjene primijeniće se na korištenje ove aplikacije. Korisnik snosi odgovornost za tačnost i potpunost unesenih podataka prilikom kupovine. <br> <br>
          <span class="font-bold">Politika privatnosti</span> <br><br>
          Obavezujemo se pružati zaštitu o ličnim podacima korisnika, na način da prikupljamo samo nužne, osnovne podatke o korisnicima koji su neophodni za ispunjenje naših obaveza; informišemo korisnike o načinu korištenja prikupljenih podataka, redovno dajemo korisnicima mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni sa lista koje se koriste za marketinške kampanje. <br>
          Svi podaci o korisnicima se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni za obavljanje posla. Svi naši zaposleni i poslovni partneri odgovorni su za poštovanje načela zaštite privatnosti.
        </p>
        <!-- <v-list class="pa-7 bg-gray-200">
         1. Uslovima korišćenja IPTV usluge (u daljem tekstu: Uslovi) uređuju se međusobni odnosi u vezi sa korišćenjem ON TV usluge između Blicnet d.o.o. Banja Luka (u daljem tekstu: Blicnet) i korisnika ON TV usluge (u daljem tekstu: korisnik). <br>
         2. U okviru Uslova korišćenja definisan je Cjenovnik korišćenja IPTV usluge, koji je dostupan na web stranici Supernova i na Supernova prodajnim mjestima. <br>
         3. IPTV usluga je usluga kojom se, putem aplikacije, omogućava pristup određenom programskom sadržaju i videu na zahtjev. <br>
         4. Osnovni uslovi za korišćenje ON TV usluge putem ON aplikacije su da: <br> <br>
            - korisnik posjeduje odgovarajući terminalni uređaj (tablet/mobilni telefon), <br>
            - ima pristup internetu (putem WiFi ili mobilne mreže) <br>
            - se nalazi na teritoriji Bosne i Hercegovine. <br> <br>
         5. Korišćenje IPTV usluge omogućava se svim korisnicima Supernova usluga, kao i drugim licima koja ne koriste Supernova usluge, a nalaze se na teritoriji Bosne i Hercegovine. <br>
         6. Korisnicima će, u okviru IPTV usluge, biti omogućeno korišćenje određenog paketa TV programa.
        </v-list> -->
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog3"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      
    >
    <v-card style="background: #e9e9e9;">
      <v-toolbar
          dark
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        >
          <v-toolbar-title class="text-white" :style="biggerScreen ? 'font-size: 20px' : 'font-size:16px'">Česta pitanja</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              variant="text"
              @click="dialog3 = false"
            >
              <v-icon size="large" color="white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="mt-8">
          <v-row justify="center">
            <v-col xs="12" sm="12" md="10" lg="10" xl="8">
            <v-expansion-panels elevation="4">
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Šta je MOVE TV?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  MOVE TV je usluga koja putem MOVE aplikacije omogućava gledanje najtraženijih TV kanala bilo gdje u BiH preko smart uređaja: televizora, telefona i tableta. MOVE TV je takođe moguće gledati i pomoću Android  set top boxa (STB) ukoliko ne posjedujete pametni televizor. <br> <br>
                  MOVE TV omogućava korišćenje naprednih TV funkcionalnosti na najvećem broju kanala, kao što su gledanje propuštenog sadržaja i do 7 dana unazad, pauziranje ili premotavanje sadržaja na početak, te video na zahtjev.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Šta je sve potrebno da bih gledao MOVE TV?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Za uživanje u MOVE sadržajima potreban je pristup internetu, pametni uređaj (televizor, telefon ili tablet) ili STB. <br>
                  MOVE aplikacija dostupna je za uređaje sa operativnim sistemima Android, iOS i Harmony OS (Huawei uređaji novije generacije) i 
                  moguće ju je preuzeti sa <a href="https://play.google.com/store/apps/details?id=com.mtssi.supernova" target="_blank" class="no-underline text-red-700 font-semibold"> Google Play Store-a</a>, 
                  <a href="https://apps.apple.com/my/app/supernovaiptv/id1628850139" target="_blank" class="no-underline text-red-700 font-semibold">App Store-a</a> ili 
                  <a href="https://appgallery.huawei.com/app/C109761403" target="_blank" class="no-underline text-red-700 font-semibold">Huawei AppGallery</a>.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Gdje mogu da vidim programsku šemu?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Programska šema dostupna je na ovom <router-link @click="dialog3 = false" :to="{name: 'ChannelsView'}" class="no-underline text-red-700 font-semibold">linku</router-link>. Šema obuhvata više od 190 TV i radio kanala najpopularnijih domaćih, regionalnih i svjetskih TV kuća za sve ukuse i sve generacije.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Koje videoteke mogu da koristim u okviru MOVE TV usluge?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  U MOVE video klubu dostupne su brojne videoteke sa bogatim sadržajem strane i domaće produkcije - Superstar, Apollon, Pickbox NOW, FilmBox, Nick+, AXN NOW i Epic Drama. 
                  Lista videoteka se kontinuirano dopunjava.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Da li mogu da gledam MOVE TV u inostranstvu?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Da, određenu grupu TV kanala moguće je gledati u inostranstvu. Pozovite Kontakt centar na broj 0800 30 630 za više informacija.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Koja brzina interneta je potrebna za korištenje MOVE TV usluge?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Internet brzina direktno utiče na kvalitet stream-a MOVE TV-a. <br>  
                  Za korištenje MOVE TV usluga na Smart TV-u ili mobilnom uređaju, potreban je minimalan protok od 3Mbps za SD, odnosno 5Mbps za HD sadržaj. 
                  Za postizanje optimalnih perfomansi i praćenje servisa sa najboljim kvalitetom slike, preporučena brzina protoka je od 5 Mbps za SD i 10 Mbps za HD sadržaj.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Na koliko uređaja istovremeno mogu da gledam MOVE sadržaj?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Prilikom aktivacije MOVE TV usluge omogućena je registracija maksimalno tri uređaja za istovremeno gledanje TV sadržaja. <br> 
                  Uklanjanje već registrovanih uređaja moguće je u okviru <router-link @click="dialog3 = false" class="no-underline text-red-700 font-semibold" :to="{name: 'ProfileView'}">korisničkog naloga</router-link> na ovom portalu, ili pozivom na besplatan broj Korisničke podrške 0800 30 630.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Da li mogu da probam besplatno MOVE TV? Da li nudite pogodnosti i promocije za nove korisnike?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Da. Prlikom registracije novog naloga na sajtu www.movetv.ba, korisnik dobija 24 sata besplatnog korišćenja MOVE TV usluge, bez dodatnih obaveza.
                  Takođe, uvijek imamo aktuelne promocije uz koje korisnik može da iskoristi posebne pogodnosti. Obratite se našoj Korisničkoj podršci za više detalja.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Koje opcije plaćanja su dostupne?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  MOVE TV uslugu moguće je koristiti kroz <span class="font-semibold">postpaid</span> ili <span class="font-semibold">prepaid</span> način plaćanja. <br> <br>
                  <span class="font-semibold pb-4">Prepaid registracija i plaćanje</span> <br>
                  Za korištenje MOVE TV usluge uz prepaid način plaćanja (plaćanje unaprijed), potrebna je registracija putem web portala www.movetv.ba, nakon čega je moguće odabrati željeni paket. Prilikom registracije potrebno je da pravilno unesete vaše ime i prezime, korisničko ime (e-mail adresa koju koristite) i generišete svoju lozinku za pristup. Nakon registracije potrebno je da odaberete željeni period korištenja MOVE TV usluge i pokrenete proces onlajn plaćanja. <br>
                  Korištenje prepaid MOVE TV usluge moguće je na 7, 15 ili 30 dana, uz opciju automatskog obnavljanja pretplate. 
                  Plaćanje odabranog perioda korištenja MOVE TV usluge omogućeno je putem MasterCard, Maestro i Visa platnih kartica. Po prijemu potvrde o uspješnom plaćanju MOVE TV usluge, što se smatra trenutkom aktivacije MOVE TV usluge, korisnik se upućuje na MOVE aplikaciju i Google Play/App store/App Gallery za preuzimanje i instalaciju MOVE aplikacije. 
                  Korisnik u okviru MOVE aplikacije unosi svoje podatke za registraciju (korisničko ime i lozinku) koji se koriste za prijavu. 
                  Za korištenje MOVE TV paketa na 7 ili 15 dana neophodna je platna kartica koju je potrebno registrovati, dok se plaćanje vrši jednokratno za izabrani peirod korišćenja. Nakon verifikacije uplate, korisniku će biti dostupni kanali bez automatske periodične naplate. 
                  Korištenje MOVE TV usluge na period od mjesec dana podrazumijeva aktivaciju usluge uz automatsko produžavanje svaki naredni mjesec sve dok korisnik ne izvrši deaktivaciju MOVE TV usluge putem web portala www.movetv.ba. 
                  U slučaju nemogućnosti automatske naplate naknade za period korištenja MOVE TV usluge u trajanju od mjesec dana za naredni pretplatni period, korisniku će biti onemogućeno korištenje usluge. Za dalje korištenje usluge korisnik mora inicirati uplatu, kao i prilikom inicijalnog plaćanja i aktivacije usluge. <br> <br>
                  <span class="font-semibold pb-4">Deaktivacija usluge </span> <br>
                  Kada korisnik MOVE TV usluge uz prepaid način plaćanja zahtjeva deaktivaciju MOVE TV usluge putem web portala www.movetv.ba, deaktivacija usluge će se izvršiti zaključno sa završetkom trajanja perioda od mjesec dana za koji je korisnik pretplaćen. U tom slučaju, korisniku će biti omogućeno korištenje MOVE TV usluge do kraja perioda važenja. <br> <br>
                  <span class="font-semibold pb-4">Postpaid registracija i plaćanje </span> <br>
                  Za korištenje MOVE TV usluge uz postpaid način plaćanja potrebno je da korisnik zaključi ugovor sa Blicnetom. Prilikom zaključenja ugovora korisniku će biti dodijeljeni korisničko ime i lozinka za pristup MOVE aplikaciji.
                  Mjesečna naknada za korištenje MOVE TV usluge uz postpaid način plaćanja je definisana <a class="text-red-700 font-semibold no-underline" href="https://www.supernovabih.ba/wp-content/uploads/2024/04/supernova-cjenovnik-2024-04-13.pdf" target="_blank" rel="noopener noreferrer">Cjenovnikom Blicneta</a> za koju će se korisniku izdavati računi sa valutom plaćanja. 
                  Prava i obaveze korisnika MOVE TV usluge sa postpaid modelom plaćanja, kao i drugi uslovi korištenja usluga, kao i uslovi raskida ugovora regulisana su posebnim ugovorom koji korisnik zaključuje sa Blicnetom i <a class="no-underline text-red-700 font-semibold" href="https://www.supernovabih.ba/wp-content/uploads/2024/04/Opsti-uslovi-poslovanja-2024-04-13.pdf" target="_blank" rel="noopener noreferrer">Opštim uslovima poslovanja Blicneta</a>.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Gdje mogu da vidim do kada mi važi pretplata za MOVE TV?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Svi podaci o aktivnim pretplatama dostupni su u okviru <router-link @click="dialog3 = false" class="no-underline text-red-700 font-semibold" :to="{name: 'ProfileView'}">korisničkog naloga</router-link> na sajtu movetv.ba
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Potrebne su mi dodatne informacije, kako mogu da vas kontaktiram?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Za sva pitanja vezano za MOVE uslugu pozovite Kontakt centar na besplatan broj <span class="font-semibold">0800 30 630</span>.<br>
                  Za pitanja tehničke prirode, obratite se na e-mail <span class="font-semibold">podrska@movetv.ba</span>.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Šta je Sbon i gdje ga mogu iskoristiti?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Sbon je vrijednosni bon/vaučer na kojem se nalazi jedinstveni kod sa kojim možeš jednostavno i sigurno izvršiti plaćanje različitih usluga. <br> <br>
                  <span class="font-semibold pb-4">Putem Sbon-a možeš:</span> <br>
                  - dopuniti prepaid račun u m:tel mreži; <br>
                  - platiti Supernova MOVE TV uslugu; <br>
                  - učestvovati u igrama na sreću kod priređivača koji koriste Sbon kao sredstvo uplate. <br>
                  
                </v-expansion-panel-text>
              </v-expansion-panel> 
            </v-expansion-panels>
          </v-col>
          </v-row>
        </v-container>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog4"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      
    >
    <v-card style="background: #e9e9e9;">
      <v-toolbar
          dark
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        >
          <v-toolbar-title class="text-white" :style="biggerScreen ? 'font-size: 20px' : 'font-size:16px'">Načini plaćanja</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              variant="text"
              @click="dialog4 = false"
            >
              <v-icon size="large" color="white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="mt-8">
          <v-row justify="center">
            <v-col xs="12" sm="12" md="10" lg="10" xl="8">
            <v-expansion-panels elevation="4">
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Plaćanje bankovnom karticom
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Da bi izvršio plaćanje bankovnom karticom potrebno je da posjeduješ Masterkard ili VISA bankovnu karticu i da je registruješ za plaćanje na sajtu https://movetv.ba/profil. Proces registracije se pokreće iz korisničkog panela iz dijela  „MOJA KARTICA“ klikom da polje „DODAJ KARTICU“ i realizuje u nekoliko jednostavnih i ustaljenih koraka kod svake vrste kartičnih plaćanja na internetu. <br> <br>
                  •	Na prvom koraku se otvara obrazac gdje unosiš podatke i sva polja su obavezna i polje „DALJE“ će biti aktivno samo kada su svi podaci ispunjeni. <br>
                  •	Potom se se otvara forma gdje unosiš podatke o kartici, čekiranjem kvadrata „Potvrđujem procesiranje podataka“ i klikom na „Potvrdi“ pokreće se procesiranje kartice, provjera podataka i registracija kartice za plaćanje. <br>
                  •	Nakon unešenih podataka vrši se verifikacija dostavljanih podataka sa matičnom bankom koja može da se razlikuje zavisno od vrste integrisanih sistema zaštite i ovaj korak se može razlikovati od vrste banke izdavaoca kartice. <br>
                  •	Nakon uspješne verifikacije kartice otvoriće se stranica sa porukom o uspješnom dodavanj kartice i klikom na dugme „MOJ NALOG“ korisnik se vraća svoj panel gdje nastavlja proces kupovine. <br> <br>
                  Aktiviranje dodatnih paketa ili produžavanje perioda osnovnog paketa  se radi na stranici „PAKETI I UREĐAJI“ ,  pokreće se kliknom na okvir „PRETPLATI SE“ željenog paketa, nakon čega se pojavljuje poruka čijom potvrdom klikom na „PRETPLATI SE“ verifikuješ odabir i  
                  pokrećeš proceduru plaćanja sa svoje platne kartice koju si registrovao, a za iznos odabranog paketa ili pretplatnog perioda. Pregled transakcija je dostupan na stranici „MOJ NALOG“ u dijelu  „MOVETV NALOG“  klikom na dugme „PRIKAŽI TRANSAKCIJE“.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Plaćanje sa računa MOVETV naloga
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Da bi izvršio plaćanje dodatnog paketa ili videoteka kao i plaćanje produženja perioda osnovnog paketa potrebno je račun svog move naloga dopuniti minimalno do iznosa dovoljnog za plaćanje željenog paketa, a to možeš učiniti na dva načina: <br> <br>
                  •	Dopunom move računa iznosom sa Sbon-a. <br>
                  •	Dopunom move računa uplatom na POS terminalu. <br> <br>
                  <span class="font-semibold underline pb-4">Dopuna move računa iznosom sa Sbona-a</span> <br> <br>
                  Postupak dodavanje sredstava iznosom sa Sbon vaučera pokrećeš iz korisničkog panela iz dijela  „MOVETV NALOG“ klikom da polje „DOPUNI RAČUN“, nakon čega se otvara se šablon za unos koda gdje je potrebno da uneseš 16-cifreni kod Sbon vaučera. 
                  Po unosu validnog koda otvara se klizač za odabir iznosa i okvir za unos iznosa kojim možeš odrediti koliku sumu želiš da uplati za kupovinu MOVE TV paketa. Ukoliko ne iskoristiš cjelokupan iznos sa Sbon vaučera za dopunjavanje move TV naloga ostatak od iznosa ti ostaje na raspolaganju da iskoristiš po drugom osnovu koje Sbon omogućava. <br>
                  <span class="underline"> Po završetku postupka uplaćeni iznos ti se prikazuje na kartici „Stanje računa“ gdje pratiš svoj saldo i raspoloživa sredstava za plaćanje move paketa. </span> <br> <br>
                  <span class="font-semibold underline pb-4">Dopuna move računa uplatom na POS terminalu</span> <br> <br>
                  Da bi dopunio svoj move račun uplatom na POS terminalu potrebno je da znaš ID broj svog move naloga, a koji se nalazi u korisničkom panelu u dijelu „MOVETV NALOG“ ispod imena i prezimena koje si unio prilikom registracije naloga. 
                  Operater kod kojeg dopunjavaš move nalog će unijeti ID broj na osnovu kojeg će uplaćena sredstva dopuniti tvoj move račun. <br>
                  <span class="underline">Po završetku postupka uplaćeni iznos ti se prikazuje na kartici „Stanje računa“ gdje pratiš svoj saldo i raspoloživa sredstava za plaćanje move paketa.</span> <br> <br>
                  Aktiviranje dodatnih paketa ili produžavanje perioda osnovnog paketa  se radi na stranici „PAKETI I UREĐAJI“ ,  pokreće se kliknom na okvir „PRETPLATI SE“ željenog paketa, nakon čega se pojavljuje poruka čijom potvrdom klikom na „PRETPLATI SE“ verifikuješ odabir i  pokrećeš proceduru plaćanja sa svog računa koji si prethodno dopunio, a za iznos odabranog paketa ili pretplatnog perioda.
                  Pregled transakcija je dostupan na stranici „MOJ NALOG“ u dijelu  „MOVETV NALOG“  klikom na dugme „PRIKAŽI TRANSAKCIJE“.
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Šta je Sbon i gdje ga mogu iskoristiti?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Sbon je vrijednosni bon/vaučer na kojem se nalazi jedinstveni kod sa kojim možeš jednostavno i sigurno izvršiti plaćanje različitih usluga. <br> <br>
                  <span class="font-semibold pb-4">Putem Sbon-a možeš:</span> <br>
                  - dopuniti prepaid račun u m:tel mreži; <br>
                  - platiti Supernova MOVE TV uslugu; <br>
                  - učestvovati u igrama na sreću kod priređivača koji koriste Sbon kao sredstvo uplate. <br>
                </v-expansion-panel-text>
              </v-expansion-panel>     
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Gdje mogu kupiti Sbon?
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Vaučer je moguće kupiti na svim mjestima koja su označena Sbon logom ili zastavicom, odnosno na više od 2.400 lokacija širom Bosne i Hercegovine. Sbon potražite na kioscima, u prodavnicama ili na benzinskim stanicama. <br> <br>
                  Lokaciju u svom gradu potražite na <a class="underline text-blue-600" target="_blank" href="https://www.google.com/maps/d/u/0/viewer?mid=12Ii16kCE1aNS2sp3rQB0DG-3GEDkuKE&ll=43.96985652556863%2C17.695238800000027&z=6">linku.</a>
                </v-expansion-panel-text>
              </v-expansion-panel>     
              <v-expansion-panel>
                <v-expansion-panel-title class="text-white font-bold" style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));">
                  Dodatne informacije o Sbon-u
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  Kupovina Sbon-a je moguća u iznosu od 1,00 KM do 250,00 KM. <br> <br>
                  Sbon se prilikom plaćanja određene usluge kod partnera može iskoristiti u cjelosti za jednu kupovinu ili djelimično i to u roku od jedne godine od dana kupovine Sbon-a. <br> <br>
                  Zamjena Sbon-a za novčana sredstva nije moguća. <br> <br>
                  Za sve informacije i pitanja u vezi Sbon-a i funkcionisanja Sbon sistema možete se obratiti besplatnim pozivom na broj 0800 50 000.
                </v-expansion-panel-text>
              </v-expansion-panel>     
              </v-expansion-panels>
          </v-col>
          </v-row>
        </v-container>
    </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
import {ref} from 'vue'

export default {
    setup(){
        const links = ref([
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
        ]);

        const logo = ref(require('@/assets/images/supernova.png'));

        const dialog = ref(false);
        const dialog2 = ref(false);
        const dialog3 = ref(false);
        const dialog4 = ref(false);

        return {links, logo, dialog, dialog2, dialog3, dialog4}
    },

    computed:{
    biggerScreen () {
      if(this.$vuetify.display.width >= 620){
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style>
  .footer{
    padding-top: 60px;
    bottom: 0;
    width: 100%;
    font-family: 'Pero-Bold';
    /* position: fixed; */
  }
  .dialog-bottom-transition-enter-active,
  .dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
  }

  .page-links{
    text-decoration: none;
    color: white;
  }

  .up-arrow{
    font-size: 22px;
  }

  .dialog-terms p{
    font-family: 'Roboto-Light';
    font-size: 15px;
  }
  .dialog-payment p{
    font-family: 'Roboto-Light';
    font-size: 15px;
  }

  .bank-links{
    padding: 4px;
  }

  .sbon-logo{
    background-color: rgb(255, 255, 255);
    padding: 3.5px;
    border-radius: 4px;
    height: 35px;
    width: 43px;
    opacity: 90%;
    margin-top: 16px;
  }

  .sbon-logo:hover{
    transition: ease-in-out;
    transition-duration: 0.2s;
    opacity: 100%;
  }

  .bank-logo{
    background-color: rgb(255, 255, 255);
    padding: 3.5px;
    border-radius: 4px;
    height: 30px;
    width: 43px;
    opacity: 90%;
  }

  .bank-logo:hover{
    transition: ease-in-out;
    transition-duration: 0.2s;
    opacity: 100%;
  }
</style>