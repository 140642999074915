<template>
  <div class="changePassword">
    <v-snackbar v-model="this.passChangeSuccess" :timeout="3000" location="top center" transition="slide-y-transition"
      height="80" variant="elevated" color="success" elevation="24">
       <p class="message">{{ this.responseMessage }}</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.passChangeSuccess = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.passChangeFail" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24">
        <p class="message">{{ this.responseMessage }}</p>
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.passChangeFail = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-container>
      <v-row justify="center">
        <v-col xs="12" sm="8" md="6" lg="6" xl="4">
          <div class="form">
            <h1 class="text-4xl font-bold mb-2">Promijeni lozinku</h1>
            <p v-if="biggerScreen" class="text-lg">Lozinka mora sadržavati minimalno 8 karaktera uključujući bar jedno veliko slovo, malo slovo, broj i specijalni znak.</p>
            <p v-else class="text-sm">Lozinka mora sadržavati minimalno 8 znakova uključujući bar jedno veliko slovo, malo slovo, broj i specijalni znak.</p>
            <v-chip v-if="this.currentUser" color="red" label class="mt-2 mb-4"><v-icon start icon="mdi-account-circle-outline"></v-icon><span class="fs-6">{{ this.currentUser.ott_email }}</span></v-chip>
            <v-text-field
              v-model="currentUserCredentials.oldPassword"
              :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-2"
              label="Stara lozinka"
              hint="Unesi staru lozinku za pristup"
              class="input-group--focused mt-1"
              @click:append-inner="show1 = !show1"
              prepend-inner-icon="mdi-lock-outline"
            ></v-text-field>
            <v-text-field
              v-model="currentUserCredentials.newPassword"
              :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="input-10-2"
              label="Nova lozinka"
              hint="Unesi novu lozinku za pristup"
              class="input-group--focused mt-2"
              @click:append-inner="show2 = !show2"
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
            <!-- <v-text-field
              v-model="currentUserCredentials.confirmedPassword"
              :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="input-10-2"
              label="Potvrdi lozinku"
              hint="Unesite Vašu lozinku za pristup"
              class="input-group--focused mt-2"
              @click:append-inner="show2 = !show2"
              prepend-inner-icon="mdi-lock-outline"
            ></v-text-field> -->
            <v-row no-gutters>
              <v-col cols="12" class="text-right">
                <v-btn :disabled="isDisabled" @click="changePassword" :loading="this.loading" class="center bg-error mt-3" variant="tonal" size="large" elevation="3">
                  <span class="font-bold">Promijeni lozinku</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {ref, onBeforeMount} from 'vue'
import {useUserStore} from '../stores/UserStore'

export default {
  setup(){
    const userStore = useUserStore();
    const show1 = ref(false);
    const show2 = ref(false);
    const password = ref('Lozinka');

    let currentUserString = localStorage.getItem('user');
    let currentUser = ref(JSON.parse(currentUserString));

    let currentUserCredentials = ref({
      oldPassword: '',
      newPassword: ''
      // confirmedPassword: null
    });

    let passChangeSuccess = ref(false);
    let passChangeFail = ref(false);
    let responseMessage = ref('');
    let loading = ref(false);

    //methods

    // function changePassword() {
    //   this.userStore.logIn(this.currentUser.email, this.currentUser.password);
    // }

    onBeforeMount(() => {
      window.scrollTo(0, 0)
    });

    return {userStore, show2, show1, password, currentUserCredentials, currentUser, passChangeSuccess, passChangeFail, responseMessage, loading}
  },

  methods:{
     async changePassword(){
      this.loading = true;
      let response = await this.userStore.changePassword(this.currentUser.ott_email, this.currentUserCredentials.oldPassword, this.currentUserCredentials.newPassword);

      if(response['status'] == 0){
        this.passChangeSuccess = true;
        this.responseMessage = 'Uspješno si promijenio/la lozinku.';
        this.loading = false;
        this.currentUserCredentials.oldPassword = '';
        this.currentUserCredentials.newPassword = '';

      }else if(response['status'] == -987){
        this.passChangeFail = true;
        this.responseMessage = 'Nisu ispoštovani minimalni uslovi formata nove lozinke.';
        this.loading = false;

      }else if(response['status'] == -989){
        this.passChangeFail = true;
        this.responseMessage = 'Neispravan unos lozinke.';
        this.loading = false;

      } else if(response['status'] == -986){
        this.passChangeFail = true;
        this.responseMessage = response['message'];
        this.loading = false;

      }else{
        this.passChangeFail = true;
        this.responseMessage = 'Desila se greška. Lozinka nije promijenjena.'
        this.loading = false;
      }

    }
  },

  computed:{
      biggerScreen () {
        if(this.$vuetify.display.width >= 960){
          return true;
        }else{
          return false;
        }
      },

      isDisabled(){
        if(this.currentUserCredentials.oldPassword == '' || this.currentUserCredentials.newPassword == '' ){
        return true;
      }else{
        return false;
      }
      }
    }
}
</script>

<style>
.changePassword{
    width: 100%;
    height: 650px;
    padding-top: 70px;
    /* background: rgb(0, 0, 0, 0.2); */
    background-color: rgb(233, 233, 233);
    font-family: 'Pero-Bold';
  }

  .changePassword h1{
    font-family: 'Pero-Bold';
  }
  .changePassword p{
    font-family: 'Roboto-Light';
  }

  .message{
    padding-top: 14px;
    font-family: 'Roboto-Bold';
    font-size: 16px;
  }

</style>