<template>
    <div class="postpaid-applicaton">
      <v-snackbar v-model="this.sendSuccess" :timeout="3000" location="top center" transition="slide-y-transition" 
        height="80" variant="elevated" color="success" elevation="24">
         <p class="message text-center">Zahtjev poslan, hvala na interesovanju za naše usluge. <br> Naš operater kontaktiraće te u najkraćem roku.</p> 
          <template v-slot:actions>
            <v-btn color="white" variant="text" @click="this.sendSuccess = false">
              <v-icon size="large">mdi-close</v-icon>
            </v-btn>
          </template>
      </v-snackbar>
      <v-snackbar v-model="this.sendFail" :timeout="3000" location="top center" transition="slide-y-transition" 
        height="80" variant="elevated" color="#f58220" elevation="24">
         <p class="message text-center">Postoji nalog sa ovom e-mail adresom.</p> 
          <template v-slot:actions>
            <v-btn color="white" variant="text" @click="this.sendFail = false">
              <v-icon size="large">mdi-close</v-icon>
            </v-btn>
          </template>
      </v-snackbar>
      <v-container class="pa-6">
        <v-row justify="center">
          <v-col cols="12" md="6" lg="7" xl="6" :style="biggerScreen ? 'background-color: #ffff; border-radius: 12px; box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);' : ''">
            <div class="form">
              <v-container :style="biggerScreen ? 'padding: 30px 15px 50px 15px;' : ''">
                <h1 :style="!biggerScreen ? 'text-align:center; font-size:35px;' : ''" class="text-4xl font-bold">Podnesi zahtjev <br> <span :style="biggerScreen ? 'font-size: 27px; color:#767676;' : 'font-size:20px; color:#767676;'" >i postani postpaid korisnik</span> </h1>
                <br>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                      label="Ime i prezime"
                      clearable
                      prepend-inner-icon="mdi-account-outline"
                      v-model="this.currentUserApplication.name"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                    <v-text-field
                      label="Ulica i broj"
                      clearable
                      prepend-inner-icon="mdi-map-search-outline"
                      v-model="this.currentUserApplication.street"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                    <v-text-field
                      label="Grad"
                      clearable
                      prepend-inner-icon="mdi-city-variant-outline"
                      v-model="this.currentUserApplication.city"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                    <v-text-field
                      label="Telefon"
                      clearable
                      prepend-inner-icon="mdi-cellphone"
                      v-model="this.currentUserApplication.phone"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                    <v-text-field
                      label="Email"
                      clearable
                      prepend-inner-icon="mdi-email-outline"
                      v-model="this.currentUserApplication.email"
                      :rules="[rules.required, rules.email]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mt-1 pb-0">
                    <label for="package" class="text-grey-darken-1 mb-1 mt-1">Izaberi osnovni paket:</label>
                    <v-radio-group v-model="this.currentUserApplication.package" inline name="package">
                      <v-radio label="MOVE TV start" color="red-darken-3" value="MOVE TV start"></v-radio>
                      <v-radio label="MOVE TV" color="red-darken-3" value="MOVE TV"></v-radio>
                    </v-radio-group>
                  </v-col>
                    <v-col cols="12" class="pt-0 mb-1">
                      <v-select 
                        v-model="currentUserApplication.additionalPackages" 
                        :items="additionalPackages"
                        label="Izaberi dodatne pakete" 
                        chips 
                        multiple
                        clearable
                      ></v-select>
                    </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-textarea 
                      prepend-inner-icon="mdi-text"
                      auto-grow
                      v-model="this.currentUserApplication.note" 
                      label="Napomena">
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" class="mt-0 pb-0">
                    <label for="already_exist" class="text-grey-darken-1 mb-1 mt-1">Da li koristiš naše usluge:</label>
                    <v-radio-group v-model="this.currentUserApplication.already_exist" inline name="already_exist">
                      <v-radio label="Novi korisnik" color="red-darken-3" value="new"></v-radio>
                      <v-radio label="Postojeći korisnik" color="red-darken-3" value="old"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-2">
                    <v-checkbox
                      @click="agreedState()"
                      color="error"
                      label="Saglasan sam da se moji podaci prikupljaju i čuvaju"
                      :style="biggerScreen ? 'font-size: 17px;' : 'font-size: 13px;'"
                      :rules="[rules.required]"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pt-2 pb-0">
                    <v-btn @click="send" :loading="this.loading" size="large" block class="bg-error text-white" variant="tonal" elevation="3" :disabled="isDisabled">
                    <span class="font-bold">Pošalji</span>
                  </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-4">
                    <span v-if="biggerScreen" style="font-size: 13px; display: flex; justify-content: center; color: #b20d28;">Napomena: Dostupnost usluge zavisi od lokacije i tehničkih mogućnosti.</span>
                    <span v-else style="font-size: 14px; color: #b20d28;">Dostupnost usluge zavisi od lokacije i tehničkih mogućnosti.</span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
   
    </div>
  </template>
  <script>
  import {useUserStore} from '../stores/UserStore'
  
  export default{
    setup(){
      const userStore = useUserStore();
      return {userStore}
    },
    data(){
      return{
        currentUserApplication: {
          name: '',
          street: '',
          city: '',
          phone: '',
          email: '',
          note: '',
          package: 'MOVE TV start',
          additionalPackages: [],
          already_exist: 'new',
          agreed: false
        },
        loading: false,
        sendSuccess: false,
        sendFail: false,
        rules:{
            required: value => !!value || "Obavezno polje.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Neispravna e-mail adresa.'
            }
          },
        additionalPackages:['MOVE TV plus', 'Apollon', 'Superstar', 'Pickbox NOW', 'FilmBox'],
      }
    },
  
    methods:{
      async send(){
        
        this.loading = true;
  
        if(this.currentUserApplication.agreed == true){
            this.currentUserApplication.agreed = "1";
          }else{
            this.currentUserApplication.agreed = "0";
        }

        if(this.currentUserApplication.already_exist == 'new'){
          this.currentUserApplication.already_exist = "0";
        }else{
          this.currentUserApplication.already_exist = "1";
        }

        if(this.currentUserApplication.additionalPackages.length == 0){
          this.currentUserApplication.additionalPackages = ['Bez Dodatnih paketa'];
        }

        let response = await this.userStore.sendPostpaidApplication(this.currentUserApplication);

        if(response != null && response.apiResult[0].result_code == 0){
          this.sendSuccess = true;
          this.currentUserApplication.name = null;
          this.currentUserApplication.street = null;
          this.currentUserApplication.city = null;
          this.currentUserApplication.phone = null;
          this.currentUserApplication.email = null;
          this.currentUserApplication.note = null;
          this.currentUserApplication.package = 'MOVE TV start';
          this.currentUserApplication.additionalPackages = [];
          this.currentUserApplication.agreed = false;
          this.currentUserApplication.already_exist = 'new';
          this.loading = false;
        }else{
          this.sendFail = true;
          this.loading = false; 
        }
      },
  
      agreedState(){
        this.currentUserApplication.agreed = !this.currentUserApplication.agreed;
      }
    },
  
    computed:{
  
      isDisabled(){
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validEmail = pattern.test(this.currentUserApplication.email);
  
        if(this.currentUserApplication.name == '' || this.currentUserApplication.street == '' || this.currentUserApplication.city == '' || this.currentUserApplication.phone == '' || this.currentUserApplication.email == '' || validEmail == false || this.currentUserApplication.agreed == false){
          return true;
        }else{
          return false;
        }
      },
  
      biggerScreen () {
          if(this.$vuetify.display.width >= 960){
            return true;
          }else{
            return false;
          }
        }
    },
  
    beforeMount(){
        window.scrollTo(0, 0);
    },
  }
  
  
  </script>
  <style>
  .postpaid-applicaton{
      font-family: 'Pero-Bold';
      width: 100%;
      height: 1350px;
      /* padding: 120px 300px 0px 300px; */
      padding-top: 80px;
      /* background: rgb(0, 0, 0, 0.2); */
      background-color: rgb(233, 233, 233)
    }
  
  .sales-partners{
    padding-top: 90px;
  }
  
  
  @media only screen and (max-width: 960px){
      .postpaid-applicaton{
        height: 1330px;
        padding-top: 20px;
      }
  
      .sales-partners{
        padding-top: 50px;
      }
    }
  
  @media only screen and (min-width: 960px) and (max-width: 1279px){
      .postpaid-applicaton{
        height: 1300px;
        padding-top: 40px;
      }
      
      .sales-partners{
        padding-top: 70px;
      }
    }
  
    .message{
      padding-bottom: 10px;
      font-family: 'Roboto-Bold';
      font-size: 16px;
    }
  
    .logo{
      border-radius: 6px;
    }
    
  /* @media only screen and (min-width: 1280px){
      .form{
        width: max-content;
      }
    } */
  
  </style>