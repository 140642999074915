<template>
  <div class="signIn">
    <v-snackbar v-model="this.passwordChangedSuccessfully" :timeout="3000" location="top center" transition="slide-y-transition" 
        height="80" variant="elevated" color="success" elevation="24">
        <p class="message">Lozinka je uspješno promijenjena.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.passwordChangedSuccessfully = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    <v-snackbar v-model="this.logInFail" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24">
       <p class="message">Neispravna lozinka/e-mail adresa.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.logInFail = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.logInFailError" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24">
       <p class="message">Desila se greška, pokušajte kasnije.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.logInFailError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.regisrationSuccess" :timeout="3000" location="top center" transition="slide-y-transition" 
      :height="biggerScreen ? '90' : '110'" variant="elevated" color="success" elevation="24" class="text-center">
       <p class="message">Registracija uspješna! <br> Provjeri svoj e-mail za sve informacije o korišćenju MOVE usluge i prijavi se. </p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.regisrationSuccess = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-container class="pa-6">
      <v-row justify="center" class="mb-2">
        <v-col cols="12" class="d-flex justify-content-center" style="margin-bottom: -6px;">
          <img src="../assets/images/move-tv-logo.png" alt="logo" height="80" width="180">
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center" style="margin-top: -5px;">
          <h5 style="font-weight: 600;">PRIJAVA I REGISTRACIJA</h5>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xs="12" sm="8" md="6" lg="6" xl="4" :style="biggerScreen ? 'background-color: #ffff; border-radius: 12px; box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16); padding:30px;' : ''">
          <div class="form">
            <div style="margin-bottom: 40px;">
            <h6 style="margin-bottom: 4px;">Novi korisnici</h6>
            <p style="font-size: 15px; font-weight: 600; color: #6e6e6e">
              Ukoliko želiš da postaneš korisnik MOVE TV usluge, potrebno je da se registruješ klikom na dugme <span style="color: #b20d28; font-family: 'Roboto-Bold';">REGISTRUJ SE</span>. 
              Registracijom naloga dobijaš 24 sata promo perioda korištenja MOVE TV paketa kanala putem MOVE aplikacije. 
              U okviru MOVE portala možeš upravljati svojim nalogom, paketima i uređajima na kojima su ti dostupni MOVE sadržaji. 
            </p>
            <h6 style="margin-top:10px; margin-bottom: 4px;">Postojeći korisnici</h6>
            <p style="font-size: 15px; font-weight: 600; color: #6e6e6e">
              Ukoliko si postojeći korisnik nekog od MOVE paketa, unesi svoje kredencijale i klikom na dugme <span style="color: #b20d28; font-family: 'Roboto-Bold';">PRIJAVI SE </span> pristupaš portalu, 
              gdje možeš upravljati svojim nalogom, paketima i uređajima na kojima su ti dostupni MOVE sadržaji.
            </p>
            </div>
            <v-text-field
              label="Email adresa"
              clearable
              placeholder="johndoe@gmail.com"
              type="email"
              prepend-inner-icon="mdi-email-outline"
              v-model="this.currentUser.email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              v-model="this.currentUser.password"
              :append-inner-icon="this.show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="this.show2 ? 'text' : 'password'"
              name="input-10-2"
              label="Lozinka"
              hint="Unesi svoju lozinku za pristup"
              class="input-group--focused mt-2"
              @click:append-inner="this.show2 = !this.show2"
              prepend-inner-icon="mdi-lock-outline"
              :rules="[rules.required]"
              @keyup.enter="logIn"
            ></v-text-field>
            <div>
              <router-link class="no-underline" :to="{name: 'ForgottenPassword'}"> 
              <v-btn color="#b20d28" size="small" variant="text"><span style="font-size: 10px;">Zaboravljena lozinka?</span></v-btn>
              </router-link>
            </div>
            <p class="fw-semibold text-danger">{{ this.errorMessage }}</p>
            <v-row no-gutters>
              <v-col cols="6">
                <router-link class="no-underline" :to="{name: 'RegisterUserView'}">
                  <v-btn class="right mt-3 bg-orange text-white" size="large" variant="tonal" elevation="3">
                    <span class="font-bold">Registruj se</span>
                  </v-btn>
                </router-link>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn @click="logIn" size="large" :loading="this.loading" class="right bg-error text-white mt-3" variant="tonal"  elevation="3" :disabled="isDisabled">
                  <span class="font-bold">Prijavi se</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <span style="font-size: 13px; padding-top: 7px; color: gray;">Ukoliko imaš nekih poteškoća pozovi broj 0800 30 630.</span>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {ref, onBeforeMount} from 'vue'
import {useUserStore} from '../stores/UserStore'

export default {
  setup(){
    const userStore = useUserStore();
    return {userStore}
  },
  data(){
    return{
      show2: false,
      password: "Lozinka",
      loading: false,
      logInFail: false,
      logInFailError: false,
      regisrationSuccess: false,
      passwordChangedSuccessfully:false,
      errorMessage: "",
      currentUser: {
        email: '',
        password: '',
        loggedIn: false
      },
      loggedUserData: {},
      rules:{
          required: value => !!value || "Obavezno polje.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Neispravna e-mail adresa.'
          }
        },
    }
  },

  methods:{

    async logIn(){
      this.loading = true;
      let response = await this.userStore.logIn(this.currentUser.email, this.currentUser.password);
      
      if(response == 0){
        this.loading = false;
        this.$router.push({ name: 'ProfileView' });
      }else if(response == null){
        this.logInFailError = true;
        this.loading = false;
      }else{
        this.logInFail = true;
        this.loading = false;
      }
    
    }
  },

  beforeMount(){
      window.scrollTo(0, 0);
  },

  mounted(){
    let registrationStatus = this.$route.query.registration;
    if(registrationStatus == 'success'){
      this.regisrationSuccess = true;
    }
    let resetPasswordStatus = this.$route.query.password_reset;
    if(resetPasswordStatus == 'success'){
      this.passwordChangedSuccessfully = true;
    }
  },

  computed:{

    isDisabled(){
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let validEmail = pattern.test(this.currentUser.email);

      if(this.currentUser.email == '' || this.currentUser.password == '' || validEmail == false){
        return true;
      }else{
        return false;
      }
    },

    biggerScreen () {
        if(this.$vuetify.display.width >= 960){
          return true;
        }else{
          return false;
        }
      }
  }

}
</script>

<style>
  .signIn{
    font-family: 'Pero-Bold';
    width: 100%;
    height: 1000px;
    padding-top: 50px;
    background-color: rgb(233, 233, 233);
  }

  @media only screen and (max-width: 960px){
    .signIn{
      padding-top: 35px;
      height: 1000px;
    }
  }

  @media only screen and (min-width: 960px) and (max-width: 1280px){
    .signIn{
      height: 1100px;
    }
  }

  .signIn p{
    font-family: 'Roboto-Light';
  }

  .message{
    padding-bottom: 10px;
    font-family: 'Roboto-Bold';
    font-size: 16px;
  }
  
</style>